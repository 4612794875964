import React from "react";
import { defaultTo, find } from "lodash";
import { formatted } from "@iamilyas/store-template-library";
import { CLEARPAY_PAYMENT_PROVIDER_KEY } from "src/utils/constant";
import { Box } from "@material-ui/core";
import { paramCase } from "change-case";
import useConfig from "src/hooks/useConfig";

const CLEARPAY_PLACEMENT_ID = "clearpay-placement";
export const PRODUCT_PRICE_DISPLAY_ID = "product-price";

export const ClearpayPlacement = ({ options, currency, price }) => {
  const { config } = useConfig();
  const locale = config?.locale?.name;
  const clearpay = findClearpay(options);
  if (!clearpay) {
    return null;
  }

  return (
    <>
      <Box
        component="span"
        id={PRODUCT_PRICE_DISPLAY_ID}
        sx={{ display: "none" }}
      >
        {formatted(price)}
      </Box>
      <afterpay-placement
        id={CLEARPAY_PLACEMENT_ID}
        data-locale={defaultTo(locale, "en-GB")}
        data-currency={currency.code}
        data-amount-selector={`#${PRODUCT_PRICE_DISPLAY_ID}`}
      />
    </>
  );
};

export const updateClearpayPlacement = (options, price) => {
  const clearpay = findClearpay(options);
  if (!clearpay) {
    return;
  }
  try {
    const placementDatasource = document.getElementById(
      PRODUCT_PRICE_DISPLAY_ID
    );
    if (placementDatasource) {
      placementDatasource.innerHTML = formatted(price);
    }
  } catch (e) {
    console.error(e);
  }
};

export const findClearpay = (options) =>
  find(
    defaultTo(options, []),
    (o) => paramCase(defaultTo(o.key, "")) === CLEARPAY_PAYMENT_PROVIDER_KEY
  );
