import axios from "axios";
import { defaultTo } from "lodash";
import { paramCase } from "change-case";
import { createCheckoutSummaryRequest } from "src/utils/requests";
import { KLARNA_PAYMENT_PROVIDER_KEY } from "src/utils/constant";
import { proxyServer } from "src/config";
import { getCheckoutSummary } from "../checkout";

export const KLARNA_DEFAULT_LOCALE = "en-GB";
const KLARNA_DISCOUNT_TYPE = "discount";
const KLARNA_SHIPPING_TYPE = "shipping_fee";
const KLARNA_PRODUCT_TYPE = "physical";

const axiosInstance = axios.create({
  baseURL: `${proxyServer}/api/ext/klarna`,
  timeout: 10000,
});

const getItemType = (type) => {
  switch (paramCase(defaultTo(type, ""))) {
    case "discount":
      return KLARNA_DISCOUNT_TYPE;
    case "shipping":
      return KLARNA_SHIPPING_TYPE;
    default:
      return KLARNA_PRODUCT_TYPE;
  }
};
const createRequiredParams = (summary) => {
  try {
    return {
      // For multi-currency support this would have to be a combo of customers country / local currency. Since we do not currently support this feature
      // This will always be the tenants country / currency. All product price will be reflected in this combo. To support this feature we would need to support
      // Currency conversion on client side.
      purchase_currency: summary.currency.code,
      purchase_country: summary.country.code,
      locale: KLARNA_DEFAULT_LOCALE,
      order_amount: summary.total.amountInMinorUnits,
      order_lines: summary.items.map((item) => {
        return {
          name: item.name,
          quantity: item.quantity,
          unit_price: item.price.amountInMinorUnits,
          total_amount: item.total.amountInMinorUnits,
          type: getItemType(item.type),
          ...(item.sku && { reference: item.sku }),
          product_identifiers: {
            ...(item.colour && { color: item.colour }),
            ...(item.size && { size: item.size }),
          },
        };
      }),
    };
  } catch (e) {
    console.error(e);
    return null;
  }
};
export const createKlarnaSession = async (context, form, auth, currency) => {
  const request = createCheckoutSummaryRequest({
    currency,
    cart: context.cart,
    discount: form?.discountCode,
    shippingId: form?.shippingOption,
    shippingCountry: form?.shippingAddress?.country,
  });
  const summary = await getCheckoutSummary(request);
  const payload = {
    intent: "buy",
    ...createRequiredParams(summary),
  };
  const response = await axiosInstance.post("payments/v1/sessions", payload, {
    auth: {
      username: auth?.username,
      password: auth?.password,
    },
  });
  return response.data;
};

export const startKlarnaJourney = (
  form,
  context,
  onPaymentSuccess,
  onPaymentFailure,
  onPaymentCancel,
  onPaymentDisable
) => {
  if (!window.Klarna.Payments) {
    onPaymentFailure();
    return;
  }
  const payload = {
    ...createRequiredParams(context),
    shipping_address: {
      given_name: form?.firstName,
      family_name: form?.lastName,
      email: form?.email,
      street_address: form?.shippingAddress?.address,
      postal_code: form?.shippingAddress?.postcode,
      city: form?.shippingAddress?.city,
      country: form?.shippingAddress?.country,
    },
    billing_address: {
      given_name: form?.firstName,
      family_name: form?.lastName,
      email: form?.email,
      street_address: defaultTo(
        form?.billingAddress?.address,
        form?.shippingAddress?.address
      ),
      postal_code: defaultTo(
        form?.billingAddress?.postcode,
        form?.shippingAddress?.postcode
      ),
      city: defaultTo(form?.billingAddress?.city, form?.shippingAddress?.city),
      country: defaultTo(
        form?.billingAddress?.country,
        form?.shippingAddress?.country
      ),
    },
  };
  window.Klarna.Payments.authorize(
    {
      payment_method_category: context.paymentOption.identifier,
    },
    payload,
    (res) =>
      handleAuthoriseCallback(
        payload,
        context,
        res,
        (externalId) =>
          onPaymentSuccess(context.id, externalId, {
            amountInMinorUnits: context?.total?.amountInMinorUnits,
            currency: context?.currency?.code,
            country: context?.country?.code,
          }),
        (errorMessage) => onPaymentFailure(context.id, null, errorMessage),
        () => onPaymentCancel(context.id),
        onPaymentDisable
      )
  );
};

const handleAuthoriseCallback = (
  payload,
  context,
  res,
  onPaymentSuccess,
  onPaymentFailure,
  onPaymentCancel,
  onPaymentDisable
) => {
  if (!res.approved) {
    // NOT APPROVED AND CANNOT RETRY
    if (!res.show_form) {
      onPaymentFailure();
      onPaymentDisable(KLARNA_PAYMENT_PROVIDER_KEY);
    } else {
      // NOT APPROVED BUT RETRY AVAILABLE - REQUIRED PAYMENT JOURNEY CANCELLED BY USER
      if (!res.error) {
        onPaymentCancel();
        return;
      }
      // NOT APPROVED BUT RETRY AVAILABLE - FORM FIELD FAILED
      onPaymentFailure();
    }
  } else {
    // APPROVED AND DO NOT DISABLE OPTION
    // NO SCENARIO WHERE APPROVED BUT ALSO SHOW_FORM IS FALSE
    createKlarnaOrder(
      payload,
      context,
      res.authorization_token,
      onPaymentSuccess,
      onPaymentFailure
    );
  }
};

const createKlarnaOrder = async (
  payload,
  context,
  authToken,
  onPaymentSuccess,
  onPaymentFailure
) => {
  const auth = context.paymentOption.auth;
  const orderId = context.id;

  const httpResponse = await axiosInstance.post(
    `payments/v1/authorizations/${authToken}/order`,
    {
      ...payload,
      merchant_reference1: orderId,
    },
    {
      auth: {
        username: auth?.username,
        password: auth?.password,
      },
    }
  );
  const response = httpResponse.data;

  if (!response.error_messages && response.order_id) {
    onPaymentSuccess(response.order_id);
  } else {
    onPaymentFailure(response.error_messages?.toString());
  }
};
