import {
  REVLOUT_PAYMENT_PROVIDER_KEY,
  PAYPAL_PAYMENT_PROVIDER_KEY,
  KLARNA_PAYMENT_PROVIDER_KEY,
  CLEARPAY_PAYMENT_PROVIDER_KEY,
  STRIPE_PAYMENT_PROVIDER_KEY,
  PAY_ON_ARRIVAL_KEY,
} from "src/utils/constant";
import { defaultTo } from "lodash";
import { paramCase } from "change-case";
import { startClearpayJourney } from "./payment-options/clearpay";
import { startKlarnaJourney } from "./payment-options/klarna";
import { startPaypalJourney } from "./payment-options/paypal";
import { startRevolutJourney } from "./payment-options/revolutPaymentProvider";
import { startStripeJourney } from "./payment-options/stripe";
import { startPayOnArrivalJourney } from "./payment-options/payOnArrival";

export const startInternalPaymentJourney = async (
  form,
  context,
  onPaymentSuccess,
  onPaymentFailure,
  onPaymentCancel,
  onPaymentDisable
) => {
  switch (paramCase(defaultTo(context?.paymentOption.key, ""))) {
    case REVLOUT_PAYMENT_PROVIDER_KEY: {
      await startRevolutJourney(
        form,
        context,
        onPaymentSuccess,
        onPaymentFailure,
        onPaymentCancel
      );
      break;
    }
    case KLARNA_PAYMENT_PROVIDER_KEY: {
      startKlarnaJourney(
        form,
        context,
        onPaymentSuccess,
        onPaymentFailure,
        onPaymentCancel,
        onPaymentDisable
      );
      return;
    }
    case CLEARPAY_PAYMENT_PROVIDER_KEY: {
      await startClearpayJourney(
        form,
        context,
        onPaymentSuccess,
        onPaymentFailure,
        onPaymentCancel,
        onPaymentDisable
      );
      break;
    }
    case STRIPE_PAYMENT_PROVIDER_KEY: {
      await startStripeJourney(form, context, onPaymentFailure);
      break;
    }
    case PAY_ON_ARRIVAL_KEY: {
      await startPayOnArrivalJourney(context, onPaymentSuccess);
      break;
    }
    default: {
      break;
    }
  }
};

// Should return a payment button for user
export const startExternalPaymentJourney = (
  form,
  currency,
  auth,
  paymentKey,
  renderObject,
  onPaymentStart,
  onPaymentSuccess,
  onPaymentFailure,
  onPaymentCancel,
  onInitFailure
) => {
  switch (paramCase(defaultTo(paymentKey, ""))) {
    case PAYPAL_PAYMENT_PROVIDER_KEY: {
      return startPaypalJourney(
        form,
        currency,
        auth,
        renderObject,
        onPaymentStart,
        onPaymentSuccess,
        onPaymentFailure,
        onPaymentCancel,
        onInitFailure
      );
    }
    default: {
      return null;
    }
  }
};
