import { paramCase } from "change-case";
import { defaultTo, filter, isEmpty, reduce } from "lodash";
import track from "src/utils/analytics";

// ----------------------------------------------------------------------

const EVENT_ADD_TO_CART = "add_to_cart";
const EVENT_REMOVE_FROM_CART = "remove_from_cart";
const EVENT_VIEW_CART = "view_cart";
const EVENT_VIEW_PRODUCT = "view_item";
const EVENT_BEGIN_CHECKOUT = "begin_checkout";
const EVENT_ADD_PAYMENT_INFO = "add_payment_info";
const EVENT_ADD_SHIPPING_INFO = "add_shipping_info";
const EVENT_COMPLETE_CHECKOUT = "purchase";

export const getCartRetailPriceTotal = (products) => {
  if (isEmpty(products)) {
    return 0;
  }
  const totals = products.map(
    (_product) => _product.price.retailAmount * _product.quantity
  );
  return reduce(totals, (sum, n) => sum + n);
};

const mapProductToItem = (product) => {
  return {
    item_id: product?.id, // Always product id
    item_is_variant: !isEmpty(product?.variantId),
    ...(product.variantId && { item_variant_id: product?.variantId }),
    ...(product.variantId && { item_variant: product?.sku }),
    ...mapCommonProductToItem(product),
  };
};

const mapSummaryProductToItem = (product) => {
  return {
    item_id: product?.productId, // Summary product.id is not the product id
    item_is_variant: !isEmpty(product?.variantId),
    ...(product.variantId && { item_variant_id: product?.variantId }),
    ...(product.variantId && { item_variant: product?.sku }),
    ...mapCommonProductToItem(product),
  };
};

const mapCommonProductToItem = (product) => {
  return {
    item_name: product?.name,
    price: product?.price?.retailAmount,
    quantity: product?.quantity,
    // Custom parameters
    ...(product?.size && { item_variant_size: product?.size }),
    ...(product?.colour && { item_variant_colour: product?.colour }),
  };
};

export const addToCartEvent = (currency, product) => {
  const item = mapProductToItem(product);
  const value = product?.price?.retailAmount;

  track.event(EVENT_ADD_TO_CART, {
    currency,
    value,
    items: [item],
  });
};

export const removeFromCartEvent = (currency, product) => {
  const item = mapProductToItem(product);

  const value = product?.price?.retailAmount;
  track.event(EVENT_REMOVE_FROM_CART, {
    currency,
    value,
    items: [item],
  });
};

export const viewCartEvent = (currency, products) => {
  const cartTotal = getCartRetailPriceTotal(products);
  const items = defaultTo(products, []).map((product) =>
    mapProductToItem(product)
  );

  track.event(EVENT_VIEW_CART, {
    currency,
    value: cartTotal,
    items,
  });
};

export const viewProductEvent = (currency, product) => {
  const item = mapProductToItem(product);

  const value = product?.price?.retailAmount;

  track.event(EVENT_VIEW_PRODUCT, {
    currency,
    value,
    items: [item],
  });
};

// No coupon / discount on start of checkout. Omitting value.
export const startCheckoutEvent = (currency, products) => {
  const cartTotal = getCartRetailPriceTotal(products);
  const items = defaultTo(products, []).map((product) =>
    mapProductToItem(product)
  );

  track.event(EVENT_BEGIN_CHECKOUT, {
    currency,
    value: cartTotal,
    items,
  });
};

export const completeCheckoutEvent = (summary) => {
  const {
    id: orderId,
    retailCurrency: { code: currency },
    customer: { id: customerId },
    billing: { country: billingCountry },
    invoice: {
      shipping: { retailAmount: shippingCost },
      discount,
      total: { retailAmount: totalAmount },
      items: allItems,
    },
  } = summary;
  const filtered = filter(
    defaultTo(allItems, []),
    (o) => paramCase(defaultTo(o.type, "")) === "product"
  );
  const products = defaultTo(filtered, []).map((product) =>
    mapSummaryProductToItem(product)
  );

  addShippingInfoEvent(summary, products);
  addPaymentInfoEvent(summary, products);

  track.event(EVENT_COMPLETE_CHECKOUT, {
    currency,
    transaction_id: orderId,
    customer_int_uuid: customerId,
    billing_country: billingCountry,
    value: totalAmount,
    coupon: discount?.name,
    shipping: shippingCost,
    items: products,
  });
};

const addShippingInfoEvent = (summary, products) => {
  const {
    retailCurrency: { code: currency },
    shippingOption: { name },
    invoice: {
      shipping: { retailAmount: shippingCost },
    },
  } = summary;

  track.event(EVENT_ADD_SHIPPING_INFO, {
    currency,
    shipping_tier: name,
    value: shippingCost,
    items: products,
  });
};

const addPaymentInfoEvent = (summary, products) => {
  const {
    retailCurrency: { code: currency },
    paymentMethod,
    invoice: {
      discount,
      total: { retailAmount: totalAmount },
    },
  } = summary;

  track.event(EVENT_ADD_PAYMENT_INFO, {
    currency,
    payment_type: paymentMethod,
    value: totalAmount,
    coupon: discount?.name,
    items: products,
  });
};
