import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CheckoutPageTemplate,
  CHECKOUT_SUCCESS_STATUS,
  CHECKOUT_POST_PAYMENT_FAILURE_STATUS,
  CHECKOUT_PAYMENT_FAILURE_STATUS,
  CHECKOUT_PROCESSING_STATUS,
  CHECKOUT_UNKNOWN_FAILURE_STATUS,
} from "@iamilyas/store-template-library";
import { find, isEmpty } from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { clearCart } from "src/redux/slices/product";
import useConfig from "src/hooks/useConfig";
import { buildNavigationPath, PATH_PAGE } from "src/routes/paths";
import {
  handleCompleteCheckout,
  handleGetPaymentOptions,
  verifyCheckoutPaymentSession,
} from "src/service/checkout";
import {
  PAYMENT_FAILED_STATUS,
  PAYMENT_PROCESSING_STATUS,
  PAYMENT_SUCCESS_STATUS,
  isStripeProdiver,
} from "src/utils/constant";
import { handleGetStoreContacts } from "src/service/host";
import { completeCheckoutEvent } from "src/hooks/useAnalytics";
import { useDispatch } from "../redux/store";

const INITIAL_REDIRECT_CHECKOUT_STAGE = {
  loading: true,
  completed: true,
  summary: null,
  status: null,
};

const INITIAL_STATE = {
  verifyArgs: false,
  verifyOrder: false,
};

const INITIAL_OPTIONS = {
  loading: false,
  data: [],
};

const INITIAL_CONTACTS = {
  loading: false,
  data: null,
};

export const STRIPE_PAYMENT_INTENT_KEY = "payment_intent_client_secret";
const STRIPE_INTERNAL_REASON = "failed_loading_stripe_client";
const STRIPE_INTENT_REASON = "failed_retrieving_stripe_payment_intent";
const STRIPE_INTENT_RESPONSE_REASON = "failed_response_stripe_payment_intent";

export default function CheckoutRedirectPage() {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { search } = useLocation();
  // STRIPE APPENDED PARAM
  const queryParams = new URLSearchParams(search);
  const stripePaymentIntentSecret = queryParams.get(STRIPE_PAYMENT_INTENT_KEY);
  // STORE
  const { config } = useConfig();

  // STATE
  const [checkoutStage, setCheckoutStage] = useState(
    INITIAL_REDIRECT_CHECKOUT_STAGE
  );
  const [state, setState] = useState(INITIAL_STATE);
  const [options, setOptions] = useState(INITIAL_OPTIONS);
  const [contacts, setContacts] = useState(INITIAL_CONTACTS);
  const isJobRan = useRef(false);
  // MISC
  const navigate = useNavigate();

  const onCompletedActions = useCallback(() => {
    dispatch(clearCart());
  }, [dispatch]);

  const updateCheckoutStage = useCallback(
    (partial) => {
      setCheckoutStage((prev) => {
        return {
          ...prev,
          ...partial,
        };
      });
    },
    [setCheckoutStage]
  );

  const updateState = useCallback(
    (partial) => {
      setState((prev) => {
        return {
          ...prev,
          ...partial,
        };
      });
    },
    [setState]
  );

  const updateOptions = useCallback(
    (partial) => {
      setOptions((prev) => {
        return {
          ...prev,
          ...partial,
        };
      });
    },
    [setOptions]
  );

  const updateContacts = useCallback(
    (partial) => {
      setContacts((prev) => {
        return {
          ...prev,
          ...partial,
        };
      });
    },
    [setContacts]
  );

  const handleNavigationClick = useCallback(
    (type, resource) => {
      if (type) {
        const path = buildNavigationPath(type, resource);
        if (path) {
          navigate(path);
        } else {
          navigate(PATH_PAGE.page404);
        }
      }
    },
    [navigate]
  );

  // BACKEND API UPDATES
  const handleFailedPayment = useCallback(
    (paymentId, errorMessage) => {
      if (orderId) {
        handleCompleteCheckout(
          orderId,
          paymentId,
          PAYMENT_FAILED_STATUS,
          errorMessage
        ).catch((e) => console.error(e));
      }
    },
    [orderId]
  );

  // CHECKOUT VIEW
  const handleUnknownError = useCallback(
    (error) => {
      updateCheckoutStage({
        loading: false,
        summary: { supportEmail: contacts.data?.support },
        status: CHECKOUT_UNKNOWN_FAILURE_STATUS,
      });
      handleFailedPayment(null, error);
    },
    [contacts.data, updateCheckoutStage, handleFailedPayment]
  );

  const handleSuccesfulPaymentJourney = useCallback(
    (paymentIntent) => {
      const summary = {
        amountInMinorUnits: paymentIntent?.amount,
        currency: paymentIntent?.currency,
        country: paymentIntent?.shipping?.address?.country,
      };

      handleCompleteCheckout(
        orderId, // BE ORDER ID
        paymentIntent?.id, // PAYMENT PROVIDER ID
        PAYMENT_SUCCESS_STATUS,
        null,
        summary
      )
        .then((response) => {
          const orderSummary = response.data;
          completeCheckoutEvent(orderSummary);
          updateCheckoutStage({
            loading: false,
            summary: orderSummary,
            status: CHECKOUT_SUCCESS_STATUS,
          });
        })
        .catch(() => {
          updateCheckoutStage({
            loading: false,
            status: CHECKOUT_POST_PAYMENT_FAILURE_STATUS,
            summary: {
              orderId,
              supportEmail: contacts.data?.support,
            },
          });
        })
        .finally(onCompletedActions);
    },
    [orderId, contacts, onCompletedActions, updateCheckoutStage]
  );

  const handleProcessingPaymentJourney = useCallback(
    (paymentIntent) => {
      // Do not need purchase summary
      const summary = {
        amountInMinorUnits: paymentIntent?.amount,
        currency: paymentIntent?.currency,
        country: paymentIntent?.shipping?.address?.country,
      };
      handleCompleteCheckout(
        orderId, // BE ORDER ID
        paymentIntent?.id, // PAYMENT PROVIDER ID
        PAYMENT_PROCESSING_STATUS,
        null,
        summary
      )
        .then((response) => {
          updateCheckoutStage({
            loading: false,
            summary: response.data,
            status: CHECKOUT_PROCESSING_STATUS,
          });
        })
        .catch(() => {
          updateCheckoutStage({
            loading: false,
            status: CHECKOUT_POST_PAYMENT_FAILURE_STATUS,
            summary: {
              orderId,
              supportEmail: contacts.data?.support,
            },
          });
        })
        .finally(onCompletedActions);
    },
    [orderId, contacts, onCompletedActions, updateCheckoutStage]
  );

  const handleFailedPaymentJourney = useCallback(
    (error) => {
      updateCheckoutStage({
        loading: false,
        summary: null,
        status: CHECKOUT_PAYMENT_FAILURE_STATUS,
      });
      handleFailedPayment(null, error);
    },
    [handleFailedPayment, updateCheckoutStage]
  );

  // STRIPE PAYMENT JOURNEY
  const handleCompleteStripePaymentJourney = useCallback(
    (publicKey) => {
      loadStripe(publicKey)
        .then((stripe) => {
          try {
            stripe
              .retrievePaymentIntent(stripePaymentIntentSecret)
              .then((result) => {
                if (result.error) {
                  const message = `[reason:${STRIPE_INTENT_RESPONSE_REASON}] ${result.error}`;
                  handleFailedPaymentJourney(message);
                } else {
                  const paymentIntent = result.paymentIntent;
                  switch (paymentIntent.status) {
                    case "succeeded":
                      handleSuccesfulPaymentJourney(paymentIntent);
                      break;
                    case "processing":
                      // TODO
                      handleProcessingPaymentJourney(paymentIntent);
                      break;
                    default:
                      handleFailedPaymentJourney();
                      break;
                  }
                }
              });
          } catch (e) {
            const message = `[reason:${STRIPE_INTENT_REASON}] ${e}`;
            handleUnknownError(message);
          }
        })
        .catch((e) => {
          const message = `[reason: ${STRIPE_INTERNAL_REASON}] ${e}`;
          handleUnknownError(message);
        });
    },
    [
      stripePaymentIntentSecret,
      handleFailedPaymentJourney,
      handleProcessingPaymentJourney,
      handleSuccesfulPaymentJourney,
      handleUnknownError,
    ]
  );

  const handleVerifyOrder = useCallback(
    (publicKey) => {
      verifyCheckoutPaymentSession(orderId, stripePaymentIntentSecret)
        .then((response) => {
          if (response.success) {
            handleCompleteStripePaymentJourney(publicKey);
          } else {
            handleUnknownError(
              `[stripe_payment_intent_secret:${stripePaymentIntentSecret}] Failed verification of stripe payment`
            );
          }
        })
        .catch(handleUnknownError);
    },
    [
      orderId,
      stripePaymentIntentSecret,
      handleCompleteStripePaymentJourney,
      handleUnknownError,
    ]
  );

  // OPTIONS
  const getPaymentOptions = useCallback(() => {
    if (!isEmpty(options.data) || options.loading) {
      return;
    }
    updateOptions({ loading: true });
    handleGetPaymentOptions()
      .then((response) => {
        updateOptions({ loading: false, data: response.data });
      })
      .catch(handleUnknownError);
  }, [options, handleUnknownError, updateOptions]);

  const getStoreContacts = useCallback(() => {
    if (contacts.data !== null || contacts.loading) {
      return;
    }
    updateContacts({ loading: true });
    handleGetStoreContacts()
      .then((response) => {
        updateContacts({ loading: false, data: response });
      })
      .catch(console.error);
  }, [contacts, updateContacts]);

  useEffect(() => {
    const isReady =
      state.verifyArgs &&
      !isEmpty(options.data) &&
      !options.loading &&
      !contacts.loading &&
      !isJobRan.current;
    if (!isReady) {
      return;
    }
    const stripe = find(options.data, (o) => isStripeProdiver(o.key));
    if (!stripe) {
      const message = "Cannot find stripe payment option";
      handleUnknownError(message);
      return;
    }
    isJobRan.current = true;
    const publicKey = stripe.auth.publicKey;
    handleVerifyOrder(publicKey);
  }, [
    state.verifyArgs,
    options,
    contacts,
    handleVerifyOrder,
    handleUnknownError,
  ]);

  useEffect(() => {
    if (!stripePaymentIntentSecret || !orderId) {
      navigate(PATH_PAGE.home);
      return;
    }
    if (!state.verifyArgs) {
      updateState({ verifyArgs: true });
      getPaymentOptions();
      getStoreContacts();
    }
  }, [
    state.verifyArgs,
    orderId,
    stripePaymentIntentSecret,
    getPaymentOptions,
    getStoreContacts,
    navigate,
    updateState,
  ]);

  const CheckoutRedirectPageMemo = useMemo(() => {
    const theme = config?.theme;
    const logo = config?.assets;
    const { verifyArgs } = state;

    const isViewReady = Boolean(theme && verifyArgs);

    return (
      isViewReady && (
        <>
          <CheckoutPageTemplate
            logo={logo}
            config={theme}
            checkoutStage={checkoutStage}
            // Currency will come from summary object
            currency={{}}
            handleNavigationClick={handleNavigationClick}
            // DEFAULT UNUSED FIELDS
            loading={{}}
            hasCheckoutError={{}}
            products={[]}
            paymentMethods={[]}
            shippingOptions={[]}
            shippingCountries={[]}
            handleLoadShippingOptions={() => {}}
            handleLoadPaymentOptions={() => {}}
            handleChangePaymentOption={() => {}}
            handleChangedPage={() => {}}
            handleChangedDiscount={() => {}}
            handleCheckoutFailure={() => {}}
            handleValidateDiscount={() => {}}
            handleCheckout={() => {}}
            handleGetNavigation={() => {}}
            handleGetNavigations={() => {}}
          />
        </>
      )
    );
  }, [config, checkoutStage, state, handleNavigationClick]);

  return CheckoutRedirectPageMemo;
}
