import RevolutCheckout from "@revolut/checkout";
import axios from "axios";
import { proxyServer } from "src/config";
import { PAYMENT_PROVIDER_DESCRIPTION } from "src/utils/constant";
import { isEnvironmentDevelopment } from "src/utils/environment";

const axiosInstance = axios.create({
  baseURL: `${proxyServer}/api/ext/revolut`,
  timeout: 10000,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "application/json";
  return config;
});

export const startRevolutJourney = async (
  form, // Checkout values from form
  context, // BE context
  onPaymentSuccess,
  onPaymentFailure,
  onPaymentCancel
) => {
  const response = await createOrder(context, form);
  const publicId = response.public_id;
  const rc = await RevolutCheckout(
    publicId,
    isEnvironmentDevelopment() ? "sandbox" : "prod"
  );
  rc.payWithPopup({
    name: `${form?.firstName} ${form?.lastName}`,
    email: form?.email,
    billingAddress: {
      streetLine1: form?.billingAddress?.address,
      city: form?.billingAddress?.city,
      postcode: form?.billingAddress?.postcode,
      countryCode: form?.billingAddress?.country,
    },
    onSuccess() {
      onPaymentSuccess(context.id, response.id, {
        amountInMinorUnits: response?.order_amount?.value,
        currency: response?.order_amount?.currency,
      });
    },
    onError: (error) => {
      onPaymentFailure(context.id, response.id, error.message);
    },
    onCancel() {
      cancelOrder(context, response.id);
      onPaymentCancel(context.id, response.id);
    },
  });
};

const createOrder = async (context, form) => {
  const request = {
    amount: context.total.amountInMinorUnits,
    currency: context.currency.code,
    merchant_order_ext_ref: context.id,
    description: PAYMENT_PROVIDER_DESCRIPTION,
    email: form?.email,
  };

  const response = await axiosInstance.post(`orders`, request, {
    headers: {
      Authorization: `Bearer ${context.paymentOption.auth.token}`,
    },
  });
  return response.data;
};

const cancelOrder = async (context, id) => {
  try {
    await axiosInstance.post(`orders/${id}/cancel`, null, {
      headers: {
        Authorization: `Bearer ${context.paymentOption.auth.token}`,
      },
    });
  } catch (e) {
    console.error(e);
  }
};
