import { isEmpty, isEqual, random, sample, sampleSize, uniqWith } from "lodash";
import { paramCase } from "change-case";
import initial from "./initial";
// ---- USED MAINLY FOR TEMPLATING FROM THE CORE APP EDITOR

const SAMPLE_COLOURS = [
  "#BDE4A8",
  "#A09EBB",
  "#5D737E",
  "#494949",
  "#FF5D73",
  "#A37871",
  "#E5F77D",
  "#9BC1BC",
  "#C94277",
  "#82D173",
  "#4C2C69",
  "#38182F",
  "#EEE1B3",
  "#1B98E0",
  "#A4303F",
  "#FFD131",
  "#616283",
];

const DEFAULT_PRODUCT_NAME = "Product Name";
const DEFAULT_PRODUCT_DESCRIPTION =
  "A description of your product. Give as much detail as possible that is required for your customer to make an informed choice";
const DEFAULT_COLLECTION_NAME = "Collection Name";
const DEFAULT_COLLECTION_DESCRIPTION = `<p class="ql-align-center"><strong style="color: rgb(102, 163, 224); font-family: Gaegu; font-size: 20px;">A short description of the collection highlighting what your customer can expect</strong></p>`;
export const DEFAULT_NAVIGATION_LINK = {
  id: 1,
  name: "Example Navigation",
  description: "Provide a short description of this navigation section",
  links: [
    {
      id: 1,
      name: "Example Link 1",
      navigation: {
        type: "SYSTEM_PAGE",
        path: "home",
      },
    },
    {
      id: 2,
      name: "Example Link 2",
      navigation: {
        type: null,
        path: null,
      },
    },
    {
      id: 3,
      name: "Example Link 3",
      navigation: {
        type: null,
        path: null,
      },
    },
    {
      id: 4,
      name: "Example Link 4",
      navigation: {
        type: null,
        path: null,
      },
    },
  ],
};

export const DEFAULT_NAVIGATION_LINKS = [
  { ...DEFAULT_NAVIGATION_LINK, id: 1 },
  { ...DEFAULT_NAVIGATION_LINK, id: 2 },
];

const createProducts = (size) =>
  [...Array(size)].map((_, index) => {
    const price = sample(["13.54", "7.35", "11.22", "18.99"]);
    const priceSale = sample(["33.12", "55.22", "29.10", "38.99"]);
    const priceSaleChange = sample(["34", "12", "22", "13"]);
    return {
      id: index,
      name: DEFAULT_PRODUCT_NAME,
      resource: null,
      description: DEFAULT_PRODUCT_DESCRIPTION,
      sku: "exampe-product-sku",
      status: sample(["SALE", "NEW", "OUT_OF_STOCK", null]),
      quantity: random(1, 20),
      images: [],
      image: null,
      colours: sampleSize(SAMPLE_COLOURS, random(2, 8)),
      price: {
        amount: price,
        amountInMinorUnits: price.replace(".", ""),
      },
      priceSale: {
        amount: priceSale,
        amountInMinorUnits: priceSale.replace(".", ""),
      },
      priceSaleChange: {
        amount: priceSaleChange,
        amountInMinorUnits: `${priceSaleChange}00`,
        percent: sample(["9", "12", "44", "98"]),
      },
      variants: uniqWith(
        [...Array(16)].map((_, index) => {
          const vPrice = sample(["13.54", "7.35", "11.22", "18.99"]);
          const vPriceSale = sample(["33.12", "55.22", "29.10", "38.99"]);

          return {
            id: `abcde-${index}-${random(1, 20)}`,
            sku: "example-sku-123-abc",
            colour: sample(["Red", "Blue", "Pink", "Purple"]),
            size: sample(["Small", "Medium", "Large", "Extra Large"]),
            quantity: random(1, 20),
            price: {
              amount: vPrice,
              amountInMinorUnits: vPrice.replace(".", ""),
            },
            priceSale: {
              amount: vPriceSale,
              amountInMinorUnits: vPriceSale.replace(".", ""),
            },
            priceSaleChange: {
              amount: priceSaleChange,
              amountInMinorUnits: `${priceSaleChange}00`,
              percent: sample(["9", "12", "44", "98"]),
            },
          };
        }),
        (a, b) => a.size === b.size && a.colour === b.colour
      ),
    };
  });

const createProductList = (size) =>
  [...Array(size)].map((_, index) => {
    const price = sample(["13.54", "7.35", "11.22", "18.99"]);
    const priceSale = sample(["33.12", "55.22", "29.10", "38.99"]);
    const priceSaleChange = sample(["34", "12", "22", "13"]);
    return {
      id: index,
      name: DEFAULT_PRODUCT_NAME,
      resource: null,
      status: sample(["SALE", "NEW", "OUT_OF_STOCK", null]),
      quantity: random(1, 100),
      image: null,
      colours:
        index % 3 === 0 ? null : sampleSize(SAMPLE_COLOURS, random(2, 8)),
      price: {
        amount: price,
        amountInMinorUnits: price.replace(".", ""),
      },
      priceSale: {
        amount: priceSale,
        amountInMinorUnits: priceSale.replace(".", ""),
      },
      priceSaleChange: {
        amount: priceSaleChange,
        amountInMinorUnits: `${priceSaleChange}00`,
        percent: sample(["9", "12", "44", "98"]),
      },
    };
  });

const createCollectionsList = (size) =>
  [...Array(size)].map((_, index) => ({
    id: index,
    name: DEFAULT_COLLECTION_NAME,
    resource: null,
    image: null,
    description: DEFAULT_COLLECTION_DESCRIPTION,
    count: random(1, 100, false),
  }));

export const DEFAULT_PRODUCT = createProducts(1)[0];

export const defaultEmptyProduct = () => {
  const product = createProducts(1)[0];
  return {
    ...product,
    status: "OUT_OF_STOCK",
    quantity: 0,
    price: {
      amount: "0.00",
      amountInMinorUnits: 0,
    },
    priceSale: null,
    priceSaleChange: null,
    variants: [],
  };
};

export const DEFAULT_COLLECTION = createCollectionsList(1)[0];

export const getDefaultCollections = (size) => createCollectionsList(size);

export const getDefaultPagedCollections = (size) => {
  const collections = createCollectionsList(size);
  return {
    ...initial.pagableData,
    data: collections,
    total: collections.length,
  };
};

export const getDefaultProducts = (size) => createProducts(size);

export const getDefaultPagedProducts = (size) => {
  const products = createProductList(size);
  return {
    ...initial.pagableData,
    data: products,
    total: products.length,
    filters: [
      { status: "New" },
      { status: "Out of date" },
      { colours: "Red" },
      { colours: "Purple" },
      { priceMin: "23.34" },
      { priceMax: "623.34" },
    ],
  };
};

export const getDefaultProductFilters = () => {
  const colours = [
    "Pink",
    "Red",
    "Purple",
    "Orange",
    "Blue",
    "White",
    "Black",
  ].map((colour) => {
    return {
      name: colour,
      count: random(1, 100, false),
    };
  });
  const sizes = ["Extra Large", "Large", "Medium", "Small", "Extra Small"].map(
    (size) => {
      return {
        name: size,
        count: random(1, 100, false),
      };
    }
  );
  const status = ["New", "Sale", "Out Of Stock"].map((status) => {
    return {
      name: status,
      count: random(1, 100, false),
    };
  });
  const min = random(1, 1000, true);
  const max = (min + random(1, 200, true)).toFixed(2);
  const filters = {
    status: {
      options: uniqWith(status, (o1, o2) =>
        isEqual(paramCase(o1.name), paramCase(o2.name))
      ),
    },
    sizes: {
      options: uniqWith(sizes, (o1, o2) =>
        isEqual(paramCase(o1.name), paramCase(o2.name))
      ),
    },
    colours: {
      options: uniqWith(colours, (o1, o2) =>
        isEqual(paramCase(o1.name), paramCase(o2.name))
      ),
    },
    prices: {
      min: {
        amount: min.toFixed(2),
        amountInMinorUnits: `${min.toFixed(2)}`.replace(".", ""),
      },
      max: {
        amount: max,
        amountInMinorUnits: `${max}`.replace(".", ""),
      },
    },
  };
  return {
    loading: false,
    data: filters,
  };
};

// UTILS - FALLBACK VALUES

export const fallbackValues = (loading, isTemplate, data, fallback) => {
  if (loading) {
    return data;
  }

  if (isTemplate) {
    return fallback;
  }
  return isEmpty(data) ? fallback : data;
};

export const fallbackPagesValues = (loading, isTemplate, obj, fallback) => {
  if (loading) {
    return obj;
  }
  if (isTemplate) {
    return fallback;
  }
  return isEmpty(obj.data) ? fallback : obj;
};
