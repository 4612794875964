import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CartPageTemplate, CART_PATH } from "@iamilyas/store-template-library";
import { useNavigate } from "react-router-dom";
import useConfig from "src/hooks/useConfig";
import {
  handleGetNavigationById,
  handleGetCompleteStoreInformation,
  handleCreateCustomerByEmail,
  getNavigationLinkByIds,
} from "src/service/host";
import { buildNavigationPath, PATH_PAGE } from "src/routes/paths";
import { cacheCallback } from "src/utils/requests";
import { defaultTo, isEmpty } from "lodash";
import {
  getCartCount,
  getCartRetailTotal,
  getCartRetailTotalInMinorUnits,
} from "src/utils/cart";
import {
  clearCart,
  clearRecentlyViewedProducts,
  decreaseQuantity,
  increaseQuantity,
  removeFromCart,
} from "src/redux/slices/product";
import { handleGetPaymentOptions } from "src/service/checkout";
import { KlarnaMessagingScript } from "src/components/KlarnaMessagingScript";
import {
  KlarnaPlacement,
  updateKlarnaPlacement,
} from "src/components/KlarnaPlacement";
import { ClearpayScript } from "src/components/ClearpayScript";
import {
  ClearpayPlacement,
  updateClearpayPlacement,
} from "src/components/ClearpayPlacement";
import GoogleAnalyticsTrackPage from "src/components/GoogleAnalyticsTrackPage";
import { chooseCurrency, filterCartByCurrency } from "src/utils/currency";
import { DEFAULT_NAVIGATION_LINK } from "src/utils/defaults";
import { updateCurency } from "src/redux/slices/store";
import { useDispatch, useSelector } from "../redux/store";

export default function CartPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { config } = useConfig();
  const {
    currency: prevSelectedCurrency,
    options: { currencies },
    loading: { currencies: currenciesLoading },
  } = useSelector((state) => state.store);

  const capability = config?.capability;
  const storeCurrency = config?.currency;
  const currency = chooseCurrency(
    prevSelectedCurrency,
    storeCurrency,
    capability,
    currencies
  );
  const { cart: cartStore, recentlyViewedProducts } = useSelector(
    (state) => state.product
  );
  const cart = filterCartByCurrency(currency, cartStore);

  const [options, setOptions] = useState({
    payments: null,
  });
  const [messagingPlacements, setMessagingPlacements] = useState(null);

  const navigations = useRef([]);
  const storeInformation = useRef([]);
  const cartRetailTotal = getCartRetailTotal(cart);
  const cartRetailTotalInMinorUnits = getCartRetailTotalInMinorUnits(cart);

  // CLEARPAY MESSAGE PLACEMENT
  const getClearpayPlacement = useCallback(
    (options) => (
      <ClearpayPlacement
        key="clearpay-placement"
        options={options}
        currency={storeCurrency}
        price={cartRetailTotal}
      />
    ),
    [storeCurrency, cartRetailTotal]
  );
  const handleUpdateClearpayPlacement = useCallback(
    () => updateClearpayPlacement(options.payments, cartRetailTotal),
    [options.payments, cartRetailTotal]
  );

  // KLARNA MESSAGE PLACEMENT
  const KlarnaMessagingScriptMemo = useMemo(
    () => <KlarnaMessagingScript options={options.payments} />,
    [options.payments]
  );

  const handleUpdateKlarnaPlacement = useCallback(
    () => updateKlarnaPlacement(options.payments, cartRetailTotalInMinorUnits),
    [cartRetailTotalInMinorUnits, options]
  );

  const getKlarnaPlacement = useCallback(
    (options) => {
      return (
        <KlarnaPlacement
          key="klarna-placement"
          options={options}
          price={cartRetailTotalInMinorUnits}
        />
      );
    },
    [cartRetailTotalInMinorUnits]
  );

  const handleGetNavigation = useCallback(
    async (id) => {
      if (!id) {
        return new Promise((res) => {
          res(DEFAULT_NAVIGATION_LINK);
        });
      }
      return cacheCallback(navigations.current, id, handleGetNavigationById);
    },
    [navigations]
  );

  const handleGetNavigations = useCallback(async (ids) => {
    if (!ids || isEmpty(ids)) {
      return new Promise((res) => {
        res([
          { ...DEFAULT_NAVIGATION_LINK, id: 1 },
          { ...DEFAULT_NAVIGATION_LINK, id: 2 },
        ]);
      });
    }
    return getNavigationLinkByIds(ids);
  }, []);

  const handleGetStoreInformation = useCallback(async () => {
    return cacheCallback(
      storeInformation.current,
      "store",
      handleGetCompleteStoreInformation
    );
  }, []);

  const handleNavigationClick = useCallback(
    (type, resource) => {
      if (type) {
        const path = buildNavigationPath(type, resource);
        if (path) {
          navigate(path);
        } else {
          navigate(PATH_PAGE.page404);
        }
      }
    },
    [navigate]
  );

  const handleChangeCurrency = useCallback(
    (value) => {
      dispatch(clearCart());
      dispatch(clearRecentlyViewedProducts());
      dispatch(updateCurency(value));
    },
    [dispatch]
  );

  const handleCreateCustomer = useCallback(
    (email) => handleCreateCustomerByEmail(email),
    []
  );

  const handleDeleteCartProduct = useCallback(
    (id, variantId) => {
      const payload = { currency: storeCurrency?.code, id, variantId };
      dispatch(removeFromCart(payload));
    },
    [storeCurrency, dispatch]
  );

  const handleIncreaseCartQuantity = useCallback(
    (id, variantId) => {
      dispatch(increaseQuantity({ id, variantId }));
    },
    [dispatch]
  );

  const handleDecreaseCartQuantity = useCallback(
    (id, variantId) => {
      dispatch(decreaseQuantity({ id, variantId }));
    },
    [dispatch]
  );

  const handleGetPaymentMessages = useCallback(
    (options) => {
      return [getKlarnaPlacement(options), getClearpayPlacement(options)];
    },
    [getKlarnaPlacement, getClearpayPlacement]
  );

  const handleUpdatePaymentMessages = useCallback(() => {
    if (!options.payments) {
      return;
    }
    handleUpdateKlarnaPlacement();
    handleUpdateClearpayPlacement();
  }, [
    options.payments,
    handleUpdateKlarnaPlacement,
    handleUpdateClearpayPlacement,
  ]);

  const handleGetPayments = useCallback(() => {
    if (options.payments !== null) {
      return;
    }
    handleGetPaymentOptions().then((resp) => {
      setOptions((prev) => {
        return {
          ...prev,
          payments: resp.data,
        };
      });
      const messages = handleGetPaymentMessages(resp.data);
      setMessagingPlacements(messages);
    });
  }, [options.payments, handleGetPaymentMessages, setOptions]);

  useEffect(() => {
    handleGetPayments();
    handleUpdatePaymentMessages();
  }, [handleUpdatePaymentMessages, handleGetPayments]);

  const CartPageMemo = useMemo(() => {
    const theme = config?.theme;
    const logo = config?.assets;
    const cartTotal = getCartCount(cart);

    const isViewReady = Boolean(theme && currency);

    return (
      <>
        {isViewReady && (
          <>
            <ClearpayScript options={options.payments}>
              {KlarnaMessagingScriptMemo}
              <CartPageTemplate
                context={{ capability, pagePath: CART_PATH }}
                logo={logo}
                config={theme}
                currency={currency}
                currencies={currencies}
                currenciesLoading={currenciesLoading}
                products={cart}
                recentlyViewedProducts={recentlyViewedProducts}
                cartTotal={cartTotal}
                messagingPlacements={defaultTo(messagingPlacements, [])}
                onDelete={handleDeleteCartProduct}
                onIncreaseQuantity={handleIncreaseCartQuantity}
                onDecreaseQuantity={handleDecreaseCartQuantity}
                handleNavigationClick={handleNavigationClick}
                handleChangeCurrency={handleChangeCurrency}
                handleGetNavigation={handleGetNavigation}
                handleGetNavigations={handleGetNavigations}
                handleGetStoreInformation={handleGetStoreInformation}
                handleCreateCustomer={handleCreateCustomer}
              />
            </ClearpayScript>
          </>
        )}
      </>
    );
  }, [
    config,
    currency,
    currencies,
    currenciesLoading,
    capability,
    cart,
    recentlyViewedProducts,
    messagingPlacements,
    options.payments,
    KlarnaMessagingScriptMemo,
    handleDeleteCartProduct,
    handleIncreaseCartQuantity,
    handleDecreaseCartQuantity,
    handleNavigationClick,
    handleChangeCurrency,
    handleGetNavigation,
    handleGetNavigations,
    handleGetStoreInformation,
    handleCreateCustomer,
  ]);

  return (
    <GoogleAnalyticsTrackPage
      isCartPage
      currency={storeCurrency?.code}
      cart={cart}
    >
      {CartPageMemo}
    </GoogleAnalyticsTrackPage>
  );
}
