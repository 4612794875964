import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ProductsListTemplate } from "@iamilyas/store-template-library";
import { useNavigate } from "react-router-dom";
import useConfig from "src/hooks/useConfig";
import {
  handleCreateCustomerByEmail,
  handleGetNavigationById,
  handleGetCompleteStoreInformation,
  getNavigationLinkByIds,
} from "src/service/host";
import {
  handleGetProducts,
  handleGetProductsFilters,
} from "src/service/product";
import { buildNavigationPath, PATH_PAGE } from "src/routes/paths";
import { cacheCallback } from "src/utils/requests";
import { isEmpty, upperCase } from "lodash";
import { getCartCount } from "src/utils/cart";
import initial, { INITIAL_PAGE_VALUE, LAZY_PAGE_SIZE } from "src/utils/initial";
import GoogleAnalyticsTrackPage from "src/components/GoogleAnalyticsTrackPage";
import { updateCurency } from "src/redux/slices/store";
import { chooseCurrency, filterCartByCurrency } from "src/utils/currency";
import {
  clearCart,
  clearRecentlyViewedProducts,
} from "src/redux/slices/product";
import { DEFAULT_NAVIGATION_LINK } from "src/utils/defaults";
import { useDispatch, useSelector } from "../redux/store";

const INITIAL_FILTERS_STATE = {
  loading: false,
  data: null,
};

const INITIAL_LOADED_VALUES = {
  // Selected currency code
  currency: null,
};

export default function ProductsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // STATE
  const { config } = useConfig();
  const { cart: cartStore, recentlyViewedProducts } = useSelector(
    (state) => state.product
  );
  const {
    currency: prevSelectedCurrency,
    options: { currencies },
    loading: { currencies: currenciesLoading },
  } = useSelector((state) => state.store);
  const capability = config?.capability;
  const storeCurrency = config?.currency;
  const currencyStore = chooseCurrency(
    prevSelectedCurrency,
    storeCurrency,
    capability,
    currencies
  );
  const currencyCode = currencyStore?.code;
  const cart = filterCartByCurrency(currencyStore, cartStore);

  // DATA
  const pageRequest = useRef(INITIAL_PAGE_VALUE);
  const [store, setStore] = useState({
    products: initial.pagableData,
    loading: true,
  });
  const [queryParams, setQueryParams] = useState({
    sort: "desc",
    sortBy: "createdAt",
    filterName: "", // Unused
    filters: null,
  });
  const [filters, setFilters] = useState(INITIAL_FILTERS_STATE);

  // MISC
  const cartTotal = getCartCount(cart);
  const loadedValues = useRef(INITIAL_LOADED_VALUES);

  // CACHE
  const navigations = useRef([]);
  const storeInformation = useRef([]);

  const updateStore = useCallback(
    (loading, onGetProducts) => {
      setStore((prev) => {
        return {
          ...prev,
          ...(onGetProducts && { products: onGetProducts(prev.products) }),
          loading,
        };
      });
    },
    [setStore]
  );

  const resetLoadedPage = () => {
    setStore((prev) => {
      return {
        ...prev,
        products: {
          ...prev.products,
          data: [],
        },
      };
    });
    pageRequest.current = 0;
  };

  const getProducts = useCallback(
    (page, onSuccess) => {
      const { sort, sortBy, filterName, filters } = queryParams;
      handleGetProducts(
        page,
        LAZY_PAGE_SIZE,
        currencyCode,
        sortBy,
        upperCase(sort),
        filterName,
        filters
      )
        .then((response) => {
          onSuccess(response);
        })
        .catch(() => {
          updateStore(false, () => initial.pagableData);
        });
    },
    [queryParams, updateStore, currencyCode]
  );

  const handleGetAllProducts = useCallback(() => {
    updateStore(true);
    getProducts(INITIAL_PAGE_VALUE, (response) => {
      updateStore(false, () => response);
    });
  }, [getProducts, updateStore]);

  const handleGetMoreProducts = useCallback(
    (page) => {
      // Avoid setting loading here as the scroll page will handle loading.
      getProducts(page, (response) => {
        updateStore(false, (prev) => {
          return {
            ...response,
            data: prev.data.concat(response.data),
          };
        });
      });
    },
    [getProducts, updateStore]
  );

  const handleLoadMore = useCallback(() => {
    const nextPage = pageRequest.current + 1;
    pageRequest.current = nextPage;
    handleGetMoreProducts(nextPage);
  }, [handleGetMoreProducts]);

  const getProductsFilters = useCallback(() => {
    const isNotReady = filters.loading || filters.data !== null;
    const currencyUnchanged = loadedValues.current.currency === currencyCode;
    if (currencyUnchanged && isNotReady) {
      return;
    }
    loadedValues.current.currency = currencyCode;
    setFilters({ loading: true, data: null });
    handleGetProductsFilters(currencyCode)
      .then((_filters) => {
        setFilters({ loading: false, data: _filters });
      })
      .catch((e) => {
        console.error(e);
      });
  }, [filters.loading, filters.data, setFilters, currencyCode]);

  useEffect(() => {
    if (!currenciesLoading) {
      getProductsFilters();
    }
  }, [currenciesLoading, getProductsFilters]);

  useEffect(() => {
    if (currenciesLoading) {
      return () => {};
    }
    resetLoadedPage();
    handleGetAllProducts();
    return () => {
      updateStore(false, () => initial.pagableData);
    };
  }, [currenciesLoading, updateStore, handleGetAllProducts]);

  const handleChangeFiltersListener = useCallback(
    (filters) => {
      setQueryParams((prev) => {
        return { ...prev, filters };
      });
    },
    [setQueryParams]
  );

  const handleChangeOrder = useCallback(
    (sortBy, sort) => {
      setQueryParams((prev) => {
        return { ...prev, sortBy, sort };
      });
    },
    [setQueryParams]
  );

  const handleGetNavigation = useCallback(
    async (id) => {
      if (!id) {
        return new Promise((res) => {
          res(DEFAULT_NAVIGATION_LINK);
        });
      }
      return cacheCallback(navigations.current, id, handleGetNavigationById);
    },
    [navigations]
  );

  const handleGetNavigations = useCallback(async (ids) => {
    if (!ids || isEmpty(ids)) {
      return new Promise((res) => {
        res([
          { ...DEFAULT_NAVIGATION_LINK, id: 1 },
          { ...DEFAULT_NAVIGATION_LINK, id: 2 },
        ]);
      });
    }
    return getNavigationLinkByIds(ids);
  }, []);

  const handleGetStoreInformation = useCallback(async () => {
    return cacheCallback(
      storeInformation.current,
      "store",
      handleGetCompleteStoreInformation
    );
  }, []);

  const handleNavigationClick = useCallback(
    (type, resource) => {
      if (type) {
        const path = buildNavigationPath(type, resource);
        if (path) {
          navigate(path);
        } else {
          navigate(PATH_PAGE.page404);
        }
      }
    },
    [navigate]
  );

  const handleChangeCurrency = useCallback(
    (value) => {
      dispatch(clearCart());
      dispatch(clearRecentlyViewedProducts());
      dispatch(updateCurency(value));
    },
    [dispatch]
  );

  const handleCreateCustomer = useCallback(
    (email) => handleCreateCustomerByEmail(email),
    []
  );

  const ProductsPageMemo = useMemo(() => {
    const products = store.products;
    const theme = config?.theme;
    const logo = config?.assets;
    const isViewReady = Boolean(theme && currencyStore);

    return (
      isViewReady && (
        <ProductsListTemplate
          context={{ capability }}
          logo={logo}
          config={theme}
          currency={currencyStore}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          cartTotal={cartTotal}
          loading={store.loading}
          products={products}
          recentlyViewedProducts={recentlyViewedProducts}
          queryParams={queryParams}
          filters={filters}
          handleLoadMore={handleLoadMore}
          handleChangeOrder={handleChangeOrder}
          handleChangeFilters={handleChangeFiltersListener}
          handleNavigationClick={handleNavigationClick}
          handleChangeCurrency={handleChangeCurrency}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        />
      )
    );
  }, [
    config,
    currencyStore,
    currencies,
    currenciesLoading,
    capability,
    store,
    recentlyViewedProducts,
    cartTotal,
    queryParams,
    filters,
    handleLoadMore,
    handleChangeOrder,
    handleChangeFiltersListener,
    handleNavigationClick,
    handleChangeCurrency,
    handleGetNavigation,
    handleGetNavigations,
    handleGetStoreInformation,
    handleCreateCustomer,
  ]);

  return (
    <GoogleAnalyticsTrackPage>{ProductsPageMemo}</GoogleAnalyticsTrackPage>
  );
}
