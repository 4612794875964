import { useEffect } from "react";
import { CLEARPAY_SCRIPT_URL } from "src/utils/constant";
import { findClearpay } from "./ClearpayPlacement";

export const ClearpayScript = ({ options, children }) => {
  useEffect(() => {
    const clearpay = findClearpay(options);
    if (clearpay) {
      const script = document.createElement("script");
      script.src = CLEARPAY_SCRIPT_URL;
      document.body.appendChild(script);
      return () => {
        const existingScript = document.querySelectorAll(
          `script[src*='afterpay']`
        );
        existingScript.forEach((script) => script.remove());
      };
    }
    return () => {};
  }, [options]);

  return children;
};
