const CONFIG_CACHE_TTL = 0; // MINUTES

const isActiveConfig = (config) => {
  if (!config) {
    return false;
  }

  const currentTime = Date.now();
  return config.ttl > currentTime;
};

const getCurrentConfig = () => {
  const configKey = getConfigKey();
  const config = window.localStorage.getItem(configKey);
  return JSON.parse(config);
};

const cacheConfig = (config) => {
  try {
    const ttl = addMinutes(Date.now(), CONFIG_CACHE_TTL);
    const configKey = getConfigKey();
    const storage = {
      ttl,
      data: {
        ...config,
      },
    };
    localStorage.setItem(configKey, JSON.stringify(storage));
  } catch (e) {
    console.error(e);
  }
};

const addMinutes = (date, minutes) => {
  return new Date(date + minutes * 60000).getTime();
};

const getConfigKey = () => {
  return `cfg-${process.env.REACT_APP_UUID}`;
};

export { isActiveConfig, getCurrentConfig, cacheConfig };
