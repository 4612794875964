import { map, isEmpty, remove, findIndex, uniqBy, find } from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { addToCartEvent, removeFromCartEvent } from "src/hooks/useAnalytics";

const MAX_RECENTLY_VIEWE_PRODUCTS = 10;

const isMatch = (product, id, variantId) =>
  product.id === id && product.variantId === variantId;

// ----------------------------------------------------------------------

export const productInitialState = {
  query: {
    sort: null,
    sortBy: null,
  },
  cart: [],
  recentlyViewedProducts: [],
};

const slice = createSlice({
  name: "product",
  initialState: productInitialState,
  reducers: {
    // Cart
    addToCart(state, action) {
      const { currency, product } = action.payload;
      const { id, variantId } = product;
      if (isEmpty(state.cart)) {
        state.cart.push(product);
        // Only send event for a new cart action
        addToCartEvent(currency, product);
      } else {
        const index = findIndex(state.cart, { id, variantId });
        if (index >= 0) {
          state.cart[index] = product;
        } else {
          state.cart.push(product);
          // Only send event for a new cart action
          addToCartEvent(currency, product);
        }
      }
    },
    removeFromCart(state, action) {
      const { currency, id, variantId } = action.payload;
      const predicate = (_product) => isMatch(_product, id, variantId);
      const found = find(state.cart, predicate);
      removeFromCartEvent(currency, found);
      remove(state.cart, predicate);
    },
    increaseQuantity(state, action) {
      const { id, variantId } = action.payload;
      const updateCart = map(state.cart, (_product) => {
        if (isMatch(_product, id, variantId)) {
          return {
            ..._product,
            quantity: _product.quantity + 1,
          };
        }
        return _product;
      });
      state.cart = updateCart;
    },
    decreaseQuantity(state, action) {
      const { id, variantId } = action.payload;
      const updateCart = map(state.cart, (_product) => {
        if (isMatch(_product, id, variantId)) {
          return {
            ..._product,
            quantity: _product.quantity - 1,
          };
        }
        return _product;
      });
      state.cart = updateCart;
    },
    // Recently Viewed Products
    addProductToRecentlyViewed(state, action) {
      const product = action.payload;
      const entry = {
        id: product?.id,
        name: product?.name,
        resource: product?.resource,
        price: product?.price,
        priceSale: product?.priceSale,
        priceSaleChange: product?.priceSaleChange,
        colours: product?.colours,
        status: product?.status,
        image: product?.image,
      };
      state.recentlyViewedProducts = uniqBy(
        [entry, ...state.recentlyViewedProducts].slice(
          0,
          MAX_RECENTLY_VIEWE_PRODUCTS
        ),
        "id"
      );
    },
    clearCart(state) {
      state.cart = [];
    },
    clearRecentlyViewedProducts(state) {
      state.recentlyViewedProducts = [];
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addToCart,
  removeFromCart,
  clearCart,
  increaseQuantity,
  decreaseQuantity,
  addProductToRecentlyViewed,
  clearRecentlyViewedProducts,
} = slice.actions;
