import { useCallback, useEffect, useRef, useState } from "react";
import { DEFAULT_EMAIL_COMMS_SUBSCRIPTION } from "@iamilyas/store-template-library";
import { lowerCase, isEmpty, first, defaultTo } from "lodash";
import useStripe from "src/hooks/useStripe";
import { useNavigate } from "react-router-dom";
import {
  getCheckoutSummary,
  handleCompleteCheckout,
  handleStartCheckout,
  handleGetShippingOptions,
  handleCreateCheckoutSession,
} from "src/service/checkout";
import {
  getPaymentIntent,
  getPaymentIntentRequest,
} from "src/service/payment-options/stripe";
import {
  PAYMENT_FAILED_STATUS,
  STRIPE_PAYMENT_LABEL,
  STRIPE_PAYMENT_PROVIDER_KEY,
} from "src/utils/constant";
import { PATH_PAGE } from "src/routes/paths";
import { STRIPE_PAYMENT_INTENT_KEY } from "src/pages/CheckoutRedirectPage";
import {
  ACTIVE_CHECKOUT_ERROR,
  INITIAL_CHECKOUT_ERROR,
} from "src/pages/CheckoutPage";
import useDiscount from "src/hooks/useDiscount";
import { createCheckoutSummaryRequest } from "src/utils/requests";

const getFormRequest = (ev, paymentOptionId, discount) => {
  const shippingDetails = ev.shippingAddress;
  const billingDetails = ev.paymentMethod?.billing_details?.address;
  return {
    firstName: ev.payerName,
    lastName: "", // FULL NAME IS IN FIRST NAME
    email: ev.payerEmail,
    shippingOption: ev.shippingOption?.id,
    paymentOption: paymentOptionId,
    emailCommunicationAccepted: DEFAULT_EMAIL_COMMS_SUBSCRIPTION,
    ...(!isEmpty(discount) && { discountCode: discount }),
    billingSameAsShipping: true, // DEFAULT IF VALUE MISSING
    ...(shippingDetails && {
      shippingAddress: {
        address: shippingDetails.addressLine?.join(", "),
        city: shippingDetails.city,
        postcode: shippingDetails.postalCode,
        country: shippingDetails.country,
      },
    }),
    ...(billingDetails && {
      billingSameAsShipping: false,
      billingAddress: {
        address: [billingDetails.line1, billingDetails.line2].join(", "),
        city: billingDetails.city,
        postcode: billingDetails.postal_code,
        country: billingDetails.country,
      },
    }),
  };
};

const getStripeShipping = (ev) => {
  const shippingDetails = ev.shippingAddress;
  return {
    name: shippingDetails.recipient,
    address: {
      line1: shippingDetails.addressLine?.join(", "),
      city: shippingDetails.city,
      country: shippingDetails.country,
      postal_code: shippingDetails.postalCode,
    },
  };
};

const getErrorMessage = (error) => [error?.code, error?.message].join(" | ");

const INITIAL_STRIPE_EXPRESS_STATE = {
  disabled: false,
  loading: false,
};

export const StripeExpressProvider = ({
  loading,
  stripe,
  context: { form, cart, currency: currencyStore },
  handleCheckoutFailure,
  updatePaymentOption,
  children,
}) => {
  const { getStripeClient } = useStripe();
  const { checkDiscountChanged } = useDiscount();
  const navigate = useNavigate();
  const enabled = Boolean(stripe !== null && stripe?.enabled);
  const publicKey = stripe?.auth?.publicKey;
  const stripeAccountCountry = stripe?.country;
  const paymentOptionId = stripe?.id;
  const discount = form?.discountCode;
  const discountState = checkDiscountChanged(discount);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const cachedClient = useRef(null);
  const stateRef = useRef(INITIAL_STRIPE_EXPRESS_STATE);
  const selectedCurrencyCode = currencyStore?.code;

  const updateState = useCallback((state) => {
    stateRef.current = {
      ...stateRef.current,
      ...state,
    };
  }, []);

  const handleDisablePaymentOptions = useCallback(() => {
    handleCheckoutFailure({
      ...INITIAL_CHECKOUT_ERROR.information,
      displayPayments: false,
    });
    updateState({ disabled: true, loading: false });
    updatePaymentOption(STRIPE_PAYMENT_PROVIDER_KEY, {
      paymentRequest: {
        loading: false,
      },
    });
  }, [updateState, updatePaymentOption, handleCheckoutFailure]);

  const handleFailure = useCallback(
    (propagate, orderId, paymentProviderId, errorMessage) => {
      if (propagate) {
        handleCheckoutFailure(ACTIVE_CHECKOUT_ERROR);
      }
      if (orderId) {
        handleCompleteCheckout(
          orderId,
          paymentProviderId,
          PAYMENT_FAILED_STATUS,
          errorMessage,
          selectedCurrencyCode
        );
      }
    },
    [handleCheckoutFailure, selectedCurrencyCode]
  );

  const getCachedClient = useCallback(
    async (publicKey) => {
      if (cachedClient.current !== null) {
        return cachedClient.current;
      }
      const client = await getStripeClient(publicKey);
      cachedClient.current = client;
      return client;
    },
    [getStripeClient]
  );

  const hasDiscountChanged = useCallback(() => {
    const isDiscountChanged = discountState.isDiscountChanged;
    // Reset state and try loading intent again if discount changed
    if (isDiscountChanged) {
      updateState(INITIAL_STRIPE_EXPRESS_STATE);
      setPaymentRequest(null);
    }
  }, [updateState, discountState]);

  const handleStripExpressInit = useCallback(async () => {
    try {
      hasDiscountChanged();
      const state = stateRef.current;
      const isNotReady =
        loading ||
        state.loading ||
        state.disabled ||
        !enabled ||
        !stripeAccountCountry ||
        !publicKey ||
        paymentRequest !== null;
      if (isNotReady) {
        return;
      }
      updateState({ loading: true });
      updatePaymentOption(STRIPE_PAYMENT_PROVIDER_KEY, {
        paymentRequest: {
          loading: true,
        },
      });
      const client = await getCachedClient(publicKey);
      // No form data as this is an express checkout
      const request = createCheckoutSummaryRequest({
        currency: selectedCurrencyCode,
        cart,
        discount,
      });

      getCheckoutSummary(request)
        .then((summary) => {
          const pr = client.paymentRequest({
            // The two-letter country code of your Stripe account (e.g., US).
            country: stripeAccountCountry,
            currency: lowerCase(summary.currency.code),
            total: {
              label: STRIPE_PAYMENT_LABEL,
              amount: Number(summary.total.amountInMinorUnits),
            },
            requestPayerName: true,
            requestPayerEmail: true,
            requestShipping: true,
          });

          // Check the availability of the Payment Request API.
          pr.canMakePayment().then((result) => {
            if (result) {
              updateState({ loading: false });
              setPaymentRequest(pr);
              updatePaymentOption(STRIPE_PAYMENT_PROVIDER_KEY, {
                paymentRequest: {
                  client,
                  data: pr,
                  loading: false,
                },
              });
            } else {
              handleDisablePaymentOptions();
            }
          });
        })
        .catch(() => {
          handleDisablePaymentOptions();
        });
    } catch (e) {
      handleDisablePaymentOptions();
    }
  }, [
    paymentRequest,
    loading,
    cart,
    discount,
    publicKey,
    enabled,
    selectedCurrencyCode,
    stripeAccountCountry,
    getCachedClient,
    updatePaymentOption,
    updateState,
    hasDiscountChanged,
    handleDisablePaymentOptions,
  ]);

  const redirectToStripeCheckoutPage = useCallback(
    (orderId, clientSecret) => {
      const searchParams = new URLSearchParams();
      searchParams.set(STRIPE_PAYMENT_INTENT_KEY, clientSecret);
      handleCreateCheckoutSession(orderId, clientSecret)
        .then(() => {
          navigate({
            pathname: PATH_PAGE.checkoutRedirect(orderId),
            search: `?${searchParams.toString()}`,
          });
        })
        .catch(() => {
          // Exception will be handled on the redirect page
          navigate({
            pathname: PATH_PAGE.checkoutRedirect(orderId),
            search: `?${searchParams.toString()}`,
          });
        });
    },
    [navigate]
  );

  useEffect(() => {
    handleStripExpressInit();
  }, [handleStripExpressInit]);

  useEffect(() => {
    if (!paymentRequest || !paymentOptionId) {
      return () => {};
    }
    paymentRequest.on("shippingaddresschange", async (ev) => {
      try {
        // We can only get the shipping options here as we do not know country of customer until this point
        const options = await handleGetShippingOptions(
          ev?.shippingAddress?.country,
          cart,
          selectedCurrencyCode
        );
        if (isEmpty(options) || !options) {
          ev.updateWith({
            status: "invalid_shipping_address",
          });
          return;
        }
        const defaultOption = first(options);
        // By default first payment option will selected
        const defaultOptionId = defaultOption?.key;

        const request = createCheckoutSummaryRequest({
          currency: selectedCurrencyCode,
          cart,
          discount,
          shippingId: defaultOptionId,
          shippingCountry: ev?.shippingAddress?.country,
        });
        const summary = await getCheckoutSummary(request);
        ev.updateWith({
          status: "success",
          total: {
            label: STRIPE_PAYMENT_LABEL,
            amount: Number(summary.total.amountInMinorUnits),
          },
          shippingOptions: options.map((option) => {
            return {
              id: option.key.toString(),
              label: option.title,
              detail: defaultTo(option.description, ""),
              amount: option.price.amountInMinorUnits,
            };
          }),
        });
      } catch (e) {
        // Only need to display error in payment pop up
        ev.updateWith({ status: "fail" });
      }
    });

    paymentRequest.on("shippingoptionchange", async (ev) => {
      try {
        const request = createCheckoutSummaryRequest({
          currency: selectedCurrencyCode,
          cart,
          discount,
          shippingId: ev?.shippingOption?.id,
        });
        const summary = await getCheckoutSummary(request);
        ev.updateWith({
          status: "success",
          total: {
            label: STRIPE_PAYMENT_LABEL,
            amount: Number(summary.total.amountInMinorUnits),
          },
        });
      } catch (e) {
        // Only need to display error in payment pop up
        ev.updateWith({ status: "fail" });
      }
    });

    paymentRequest.on("paymentmethod", async (ev) => {
      var order; // eslint-disable-line no-var
      var paymentProviderId; // eslint-disable-line no-var
      if (!publicKey) {
        ev.complete("fail");
        return;
      }
      try {
        paymentProviderId = ev.paymentMethod.id;

        const request = getFormRequest(ev, paymentOptionId, discount);
        const stripeShipping = getStripeShipping(ev);
        const orderResponse = await handleStartCheckout(
          selectedCurrencyCode,
          request,
          cart
        );
        order = orderResponse.data;
        const currency = order.currency.code;

        const piRequest = getPaymentIntentRequest(
          request,
          cart,
          selectedCurrencyCode
        );

        const response = await getPaymentIntent(piRequest, cart, currency);
        const { clientSecret } = response;

        // Confirm the PaymentIntent without handling potential next actions (yet).
        const client = await getCachedClient(publicKey);

        const { paymentIntent, error: confirmError } =
          await client.confirmCardPayment(
            clientSecret,
            { payment_method: ev.paymentMethod.id, shipping: stripeShipping },
            { handleActions: false }
          );
        if (confirmError) {
          const errorMessage = getErrorMessage(confirmError);
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete("fail");
          // Send BE error but don't display error on form
          handleFailure(false, order.id, paymentProviderId, errorMessage);
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete("success");
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow.
          if (paymentIntent.status === "requires_action") {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await client.confirmCardPayment(clientSecret, {
              return_url: PATH_PAGE.checkoutRedirectUrl(order.id),
            });
            if (error) {
              // The payment failed -- ask your customer for a new payment method.
              const errorMessage = JSON.stringify(error);
              handleFailure(true, order.id, paymentProviderId, errorMessage);
            } else {
              // The payment has succeeded.
              redirectToStripeCheckoutPage(order.id, clientSecret);
            }
          } else {
            // The payment has succeeded.
            redirectToStripeCheckoutPage(order.id, clientSecret);
          }
        }
      } catch (e) {
        ev.complete("fail");
        // Send BE error but don't display error on form
        handleFailure(false, order?.id, paymentProviderId, e?.toString());
      }
    });

    return () => {
      if (paymentRequest) {
        paymentRequest.off("shippingaddresschange");
        paymentRequest.off("shippingoptionchange");
        paymentRequest.off("paymentmethod");
      }
    };
  }, [
    publicKey,
    paymentRequest,
    paymentOptionId,
    discount,
    selectedCurrencyCode,
    cart,
    getCachedClient,
    redirectToStripeCheckoutPage,
    handleFailure,
  ]);

  return children;
};
