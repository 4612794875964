import { KLARNA_PAYMENTS_CONTAINER_ID } from "@iamilyas/store-template-library";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import useDiscount from "src/hooks/useDiscount";
import { createKlarnaSession } from "src/service/payment-options/klarna";
import {
  KLARNA_PAYMENT_PROVIDER_KEY,
  KLARNA_SCRIPT_URL,
} from "src/utils/constant";

// ----------------------------------------------------------------------

const STEP_SESSION = 1;
const STEP_INIT = 2;
const STEP_COMPLETED = 3;
const INITIAL_STATE = { discount: null, step: STEP_SESSION, loading: false };

export default function KlarnaProvider({
  loading,
  klarna,
  context,
  updatePaymentOption,
  updatePaymentOptionMethod,
  handleDisablePayment,
  handleDisablePaymentMethod,
  children,
}) {
  const { checkDiscountChanged } = useDiscount();
  const { form, isLastPage, currency: selectedCurrency } = context;
  const selectedCurrencyCode = selectedCurrency.code;
  const discountState = checkDiscountChanged(form?.discountCode);
  const enabled = Boolean(klarna != null && klarna?.enabled && isLastPage);

  // USING REF TO AVOID UNNECCESSARY RE-RENDERING
  const state = useRef(INITIAL_STATE);

  // MISC
  const updateState = useCallback(
    (step, loading) =>
      (state.current = {
        ...state.current,
        step,
        loading,
      }),
    []
  );

  const resetState = useCallback(() => {
    state.current = {
      ...state.current,
      ...INITIAL_STATE,
    };
  }, []);

  const createScript = useCallback(() => {
    const script = document.createElement("script");

    script.src = KLARNA_SCRIPT_URL;

    const existingScript = document.querySelectorAll(`script[src*='klarna']`);
    const allIframes = document.querySelectorAll("iframe[id*='klarna']");

    existingScript.forEach((script) => script.remove());
    allIframes.forEach((iframe) => iframe.remove());

    document.body.appendChild(script);
  }, []);

  // CALLBACKS
  const handlePaymentMethodSuccess = useCallback(
    (id) => {
      updatePaymentOptionMethod(KLARNA_PAYMENT_PROVIDER_KEY, id, {
        loading: false,
        enabled: true,
      });
    },
    [updatePaymentOptionMethod]
  );

  const handlePaymentMethodFailure = useCallback(
    (id) => {
      handleDisablePaymentMethod(KLARNA_PAYMENT_PROVIDER_KEY, id);
    },
    [handleDisablePaymentMethod]
  );

  const handleFailure = useCallback(() => {
    updateState(STEP_COMPLETED, false);
    handleDisablePayment(KLARNA_PAYMENT_PROVIDER_KEY);
  }, [updateState, handleDisablePayment]);

  const handleLoadCallback = useCallback(
    (id, res) => {
      if (res.error) {
        handlePaymentMethodFailure(id);
        return;
      }
      if (res.show_form) {
        handlePaymentMethodSuccess(id);
        return;
      }
      handlePaymentMethodFailure(id);
    },
    [handlePaymentMethodSuccess, handlePaymentMethodFailure]
  );

  const loadKlarnaWidgets = useCallback(() => {
    if (klarna?.methods == null) {
      handleFailure();
      return;
    }
    try {
      klarna.methods.forEach((method) => {
        window.Klarna.Payments.load(
          {
            container: `#${KLARNA_PAYMENTS_CONTAINER_ID}-${method.id}`,
            payment_method_category: method.id,
          },
          (res) => handleLoadCallback(method.id, res)
        );
      });
      updateState(STEP_COMPLETED, false);
      updatePaymentOption(KLARNA_PAYMENT_PROVIDER_KEY, { loading: false });
    } catch (e) {
      console.error(e);
      handleFailure();
    }
  }, [
    klarna,
    updateState,
    updatePaymentOption,
    handleLoadCallback,
    handleFailure,
  ]);

  // VALIDATION
  const validateInit = useCallback(() => {
    if (
      klarna?.methods == null || // Need to have methods to start init & load
      isEmpty(klarna?.methods) ||
      klarna?.clientToken == null
    ) {
      return false;
    }
    return true;
  }, [klarna]);

  const validateSession = useCallback(() => {
    if (klarna.auth == null) {
      return false;
    }
    return true;
  }, [klarna]);

  // ACTIONS
  const initialize = useCallback(() => {
    if (!validateInit()) {
      handleFailure();
      return;
    }
    updateState(STEP_INIT, false);

    try {
      createScript();
      window.Klarna = {};
      window.klarnaAsyncCallback = () => {
        window.Klarna.Payments.init({ client_token: klarna.clientToken });
        loadKlarnaWidgets();
      };
    } catch (e) {
      console.error(e);
      handleFailure();
    }
  }, [
    klarna,
    createScript,
    validateInit,
    updateState,
    loadKlarnaWidgets,
    handleFailure,
  ]);

  const createPaymentSession = useCallback(async () => {
    if (!validateSession()) {
      handleFailure();
      return;
    }
    updatePaymentOption(KLARNA_PAYMENT_PROVIDER_KEY, {
      loading: true,
    });
    updateState(STEP_SESSION, true);
    try {
      const session = await createKlarnaSession(
        context,
        form,
        klarna.auth,
        selectedCurrencyCode
      );
      if (
        !session.payment_method_categories ||
        isEmpty(session.payment_method_categories) ||
        !session.client_token
      ) {
        handleFailure();
      }
      const update = {
        clientToken: session.client_token,
        methods: session.payment_method_categories.map((method) => {
          return {
            ...method,
            id: method.identifier,
            loading: true,
            enabled: true,
          };
        }),
      };
      updateState(STEP_INIT, false);
      updatePaymentOption(KLARNA_PAYMENT_PROVIDER_KEY, update);
      return;
    } catch (e) {
      handleFailure();
    }
  }, [
    klarna,
    form,
    selectedCurrencyCode,
    context,
    validateSession,
    updateState,
    updatePaymentOption,
    handleFailure,
  ]);

  const startKlarnaWorkflow = useCallback(() => {
    // STEP 1 OF 2
    if (
      enabled &&
      state.current.step === STEP_SESSION &&
      !state.current.loading
    ) {
      createPaymentSession();
    }
    // STEP 2 OF 2
    if (enabled && state.current.step === STEP_INIT && !state.current.loading) {
      initialize();
    }
  }, [enabled, createPaymentSession, initialize]);

  useEffect(() => {
    if (loading) {
      return;
    }
    // RESET STATE IF MOVED OFF LAST PAGE
    if (!isLastPage && !state.current.loading) {
      resetState();
      return; // DO NOT PROCEED
    }
    const isDiscountChanged = discountState.isDiscountChanged;
    // START KLARNA PROCESS AGAIN IF DISCOUNT HAS BEEN CHANGED
    if (isDiscountChanged && !state.current.loading) {
      resetState();
    }

    startKlarnaWorkflow();
  }, [loading, discountState, isLastPage, startKlarnaWorkflow, resetState]);

  return children;
}
