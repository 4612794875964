// routes
import Router from "./routes";
import ScrollToTop from "./components/ScrollToTop";
import GoogleAnalytics from "./components/GoogleAnalytics";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <>
      <ScrollToTop />
      <GoogleAnalytics />
      <Router />
    </>
  );
}
