import { Helmet } from "react-helmet-async";
import { KLARNA_ON_SITE_MESSAGING_SCRIPT_URL } from "src/utils/constant";
import { isEnvironmentDevelopment } from "src/utils/environment";
import { findKlarna } from "./KlarnaPlacement";

export const KlarnaMessagingScript = ({ options }) => {
  const klarna = findKlarna(options);
  const clientId = klarna?.auth?.clientId;
  return clientId ? (
    <Helmet>
      <script
        async
        {...(isEnvironmentDevelopment() && {
          "data-environment": "playground",
        })}
        src={KLARNA_ON_SITE_MESSAGING_SCRIPT_URL}
        data-client-id={clientId}
      />
    </Helmet>
  ) : null;
};
