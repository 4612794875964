import { loadScript } from "@paypal/paypal-js";
import { first } from "lodash";

const PAYPAL_NAME_LIMIT = 120;

export const startPaypalJourney = async (
  form,
  currency,
  auth,
  renderObject,
  onPaymentStart,
  onPaymentSuccess,
  onPaymentFailure,
  onPaymentCancel,
  onInitFailure
) => {
  // We have to use same currency for script as payment. For now cached tennant currency is used.
  // TODO - Look into loading currency on checkout page load and supplying an updated currency. Cache updates ~3 minutes.
  var paypal = null; // eslint-disable-line no-var
  try {
    const token = auth?.token;
    paypal = await loadScript({ "client-id": token, currency });
  } catch (e) {
    onInitFailure(e);
    return null;
  }

  const component = paypal?.Buttons({
    style: {
      layout: "horizontal",
      color: "gold",
      shape: "rect",
      label: "pay",
      tagline: false,
      height: 48,
    },
    createOrder: async (_, actions) => {
      const context = await onPaymentStart();
      return actions.order.create({
        intent: "CAPTURE",
        payer: {
          name: {
            given_name: form?.firstName?.substring(0, PAYPAL_NAME_LIMIT),
            surname: form?.lastName?.substring(0, PAYPAL_NAME_LIMIT),
          },
          email_address: form?.email,
          address: {
            address_line_1: form?.billingAddress?.address,
            admin_area_2: form?.billingAddress?.city,
            postal_code: form?.billingAddress?.postcode,
            country_code: form?.billingAddress?.country,
          },
        },
        purchase_units: [
          {
            amount: {
              currency_code: currency,
              value: context.total.amount,
            },
          },
        ],
      });
    },
    onApprove: async (_, actions) => {
      // This function captures the funds from the transaction.
      return actions.order.capture().then((details) => {
        const paymentId = details?.id;
        const purchaseUnits = first(details?.purchase_units);
        const summary = {
          amountInMajorUnits: purchaseUnits?.amount?.value,
          currency: purchaseUnits?.amount?.currency_code,
        };
        onPaymentSuccess(paymentId, summary);
      });
    },
    onError: (errorMessage) => {
      onPaymentFailure(null, errorMessage);
    },
    onCancel: (data) => {
      onPaymentCancel(data?.orderId);
    },
  });
  await component.render(renderObject);
  return component.close; // eslint-disable-line consistent-return
};
