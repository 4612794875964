import { defaultTo } from "lodash";
import { useRef } from "react";

// ----------------------------------------------------------------------

// USED TO CHECK IF DISCOUNT HAS CHANGED
const INIT_DISCOUNT_VALUE = "";
export default function useDiscount() {
  const prev = useRef(INIT_DISCOUNT_VALUE);

  const checkDiscountChanged = (value) => {
    const discountValue = defaultTo(value, INIT_DISCOUNT_VALUE);
    const didChange = prev.current !== discountValue;
    if (didChange) {
      // Maintain reference to avoid incorrect equality checks
      prev.current = discountValue;
    }
    return {
      isDiscountChanged: didChange,
      discount: discountValue,
    };
  };

  return { checkDiscountChanged };
}
