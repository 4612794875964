import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storeReducer from "./slices/store";
import productReducer from "./slices/product";
import ProductExpireTransform from "./transforms/productExpireStoreTransform";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: `${process.env.REACT_APP_UUID}-root`,
  storage,
  keyPrefix: "prs-",
  whitelist: [],
};

const storePersistConfig = {
  key: `${process.env.REACT_APP_UUID}-store`,
  storage,
  keyPrefix: "prs-",
  whitelist: ["currency"],
};

const productPersistConfig = {
  key: `${process.env.REACT_APP_UUID}-product`,
  storage,
  keyPrefix: "prs-",
  whitelist: ["query", "cart", "recentlyViewedProducts"],
  transforms: [ProductExpireTransform],
};

const rootReducer = combineReducers({
  store: persistReducer(storePersistConfig, storeReducer),
  product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
