import React from "react";
import { defaultTo, find } from "lodash";
import { KLARNA_PAYMENT_PROVIDER_KEY } from "src/utils/constant";
import { paramCase } from "change-case";
import useConfig from "src/hooks/useConfig";
import { KLARNA_DEFAULT_LOCALE } from "src/service/payment-options/klarna";

const KLARNA_PLACEMENT_ID = "klarna-placement";

export const KlarnaPlacement = ({ options, price }) => {
  const { config } = useConfig();
  const locale = config?.locale?.name;

  const klarna = findKlarna(options);
  if (!klarna) {
    return null;
  }

  return (
    <klarna-placement
      id={KLARNA_PLACEMENT_ID}
      data-key="credit-promotion-badge"
      data-locale={defaultTo(locale, KLARNA_DEFAULT_LOCALE)}
      data-purchase-amount={price}
    />
  );
};

export const updateKlarnaPlacement = (options, price) => {
  const klarna = findKlarna(options);
  if (!klarna) {
    return;
  }

  try {
    const placement = document.getElementById(KLARNA_PLACEMENT_ID);
    if (placement) {
      placement.setAttribute("data-purchase-amount", price);
      window.Klarna.OnsiteMessaging.refresh();
    }
  } catch (e) {
    console.error(e);
  }
};

export const findKlarna = (options) =>
  find(
    defaultTo(options, []),
    (o) => paramCase(defaultTo(o.key, "")) === KLARNA_PAYMENT_PROVIDER_KEY
  );
