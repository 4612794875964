import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ProductPageTemplate,
  getProductQuantityTotal,
} from "@iamilyas/store-template-library";
import { KlarnaMessagingScript } from "src/components/KlarnaMessagingScript";
import {
  KlarnaPlacement,
  updateKlarnaPlacement,
} from "src/components/KlarnaPlacement";
import { ClearpayScript } from "src/components/ClearpayScript";
import {
  ClearpayPlacement,
  updateClearpayPlacement,
} from "src/components/ClearpayPlacement";
import { useNavigate, useParams } from "react-router-dom";
import useConfig from "src/hooks/useConfig";
import { handleGetPaymentOptions } from "src/service/checkout";
import { getCartCount, isAddedToCart } from "src/utils/cart";
import { handleGetProductByResource } from "src/service/product";
import { buildNavigationPath, PATH_PAGE } from "src/routes/paths";
import { cacheCallback } from "src/utils/requests";
import { filter, isEmpty } from "lodash";
import {
  handleCreateCustomerByEmail,
  handleGetNavigationById,
  handleGetCompleteStoreInformation,
  getNavigationLinkByIds,
} from "src/service/host";
import { viewProductEvent } from "src/hooks/useAnalytics";
import GoogleAnalyticsTrackPage from "src/components/GoogleAnalyticsTrackPage";
import { updateCurency } from "src/redux/slices/store";
import {
  chooseCurrencyBySelectedCurrency,
  filterCartByCurrency,
  findSelectedCurrency,
} from "src/utils/currency";
import { DEFAULT_NAVIGATION_LINK } from "src/utils/defaults";
import {
  addToCart,
  removeFromCart,
  addProductToRecentlyViewed,
  clearRecentlyViewedProducts,
  clearCart,
} from "../redux/slices/product";
import { useDispatch, useSelector } from "../redux/store";

const INITIAL_PRODUCT_STORE = {
  product: null,
  loading: false,
};
const INITIAL_PRODUCT_STATE = {
  product: null,
};
const INITIAL_PAYMENT_STORE = {
  loading: false,
  payments: [],
};
const INITIAL_MESSAGING_PLACEMENTS = [];

const getPrice = (snapshot) => {
  return {
    amount: getProductQuantityTotal(snapshot.price.amount, snapshot.quantity),
    amountInMinorUnits: getProductQuantityTotal(
      snapshot.price.amountInMinorUnits,
      snapshot.quantity
    ),
    retailAmountInMinorUnits: getProductQuantityTotal(
      snapshot.price.retailAmountInMinorUnits,
      snapshot.quantity
    ),
  };
};

export default function ProductPage() {
  const { name: resource } = useParams();
  // This is seperated to maintain a new position each render of the resource changing
  return <ProductView key={resource} resource={resource} />;
}

const ProductView = ({ resource }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // STATE
  const { config } = useConfig();
  const {
    currency: prevSelectedCurrency,
    options: { currencies },
    loading: { currencies: currenciesLoading },
  } = useSelector((state) => state.store);
  const { cart: cartStore, recentlyViewedProducts } = useSelector(
    (state) => state.product
  );

  // DATA
  const [store, setStore] = useState(INITIAL_PRODUCT_STORE);
  const [options, setOptions] = useState(INITIAL_PAYMENT_STORE);
  const [messagingPlacements, setMessagingPlacements] = useState(
    INITIAL_MESSAGING_PLACEMENTS
  );
  // State of product selection view. Updated by `productChanged` event listener
  const [state, setState] = useState(INITIAL_PRODUCT_STATE);

  const capability = config?.capability;
  const storeCurrency = config?.currency;
  const selectedCurrency = findSelectedCurrency(
    prevSelectedCurrency,
    currencies
  );
  const currency = chooseCurrencyBySelectedCurrency(
    selectedCurrency,
    storeCurrency,
    capability
  );

  const cart = filterCartByCurrency(currency, cartStore);
  const cartTotal = getCartCount(cart);

  // CACHE
  const isPlacementsLoaded = useRef(false);
  const navigations = useRef([]);
  const storeInformation = useRef([]);

  // CLEARPAY MESSAGE PLACEMENT

  const getClearpayPlacement = useCallback(
    (options, price) => (
      <ClearpayPlacement
        key="clearpay-placement"
        options={options}
        currency={currency}
        price={price.amount}
      />
    ),
    [currency]
  );
  const handleUpdateClearpayPlacement = useCallback(
    (price) => updateClearpayPlacement(options.payments, price.amount),
    [options.payments]
  );

  // KLARNA MESSAGE PLACEMENT
  const KlarnaMessagingScriptMemo = useMemo(
    () => <KlarnaMessagingScript options={options.payments} />,
    [options.payments]
  );

  const handleUpdateKlarnaPlacement = useCallback(
    (price) =>
      updateKlarnaPlacement(options.payments, price.retailAmountInMinorUnits),
    [options.payments]
  );

  const getKlarnaPlacement = useCallback(
    (options, price) => (
      <KlarnaPlacement
        key="klarna-placement"
        options={options}
        price={price.retailAmountInMinorUnits}
      />
    ),
    []
  );

  const handleGetProduct = useCallback(() => {
    if (store.loading || store.product !== null) {
      return;
    }
    setStore({ loading: true, product: null });
    handleGetProductByResource(resource, currency?.code)
      .then((response) => {
        if (!response) {
          // If no data navigate to 404
          throw new Error("No product found");
        }
        setStore({ loading: false, product: response });
        dispatch(addProductToRecentlyViewed(response));
      })
      .catch(() => {
        setStore({ loading: false, product: null });
        navigate(PATH_PAGE.page404);
      });
  }, [dispatch, store, setStore, resource, navigate, currency]);

  const handleGetPaymentMessages = useCallback(
    (options, snapshot) => {
      if (!snapshot) {
        return [];
      }
      const total = getPrice(snapshot);
      return [
        getKlarnaPlacement(options, total),
        getClearpayPlacement(options, total),
      ];
    },
    [getKlarnaPlacement, getClearpayPlacement]
  );

  const handleUpdatePaymentMessages = useCallback(
    (snapshot) => {
      if (
        options.loading ||
        store.loading ||
        isEmpty(options.payments) ||
        store.product == null
      ) {
        return;
      }
      const total = getPrice(snapshot);
      handleUpdateKlarnaPlacement(total);
      handleUpdateClearpayPlacement(total);
    },
    [options, store, handleUpdateKlarnaPlacement, handleUpdateClearpayPlacement]
  );

  const handleGetPayments = useCallback(() => {
    if (
      options.loading ||
      options.payments === null ||
      !isEmpty(options.payments)
    ) {
      return;
    }
    setOptions({ loading: true, payments: [] });
    handleGetPaymentOptions()
      .then((resp) => {
        setOptions({
          loading: false,
          // To avoid reloading consistenly, break by setting null
          payments: isEmpty(resp.data) ? null : resp.data,
        });
      })
      .catch((e) => {
        console.error(e);
        setOptions({ loading: true, payments: [] });
      });
  }, [options, setOptions]);

  // EVENT LISTENER
  const handleProductChange = useCallback(
    (snapshot) => {
      viewProductEvent(storeCurrency?.code, snapshot);
      setState((prev) => {
        return {
          ...prev,
          product: snapshot,
        };
      });
      handleUpdatePaymentMessages(snapshot);
    },
    [storeCurrency, handleUpdatePaymentMessages]
  );

  useEffect(() => {
    if (!currenciesLoading && selectedCurrency) {
      setStore(INITIAL_PRODUCT_STORE);
    }
  }, [currenciesLoading, selectedCurrency]);

  useEffect(() => {
    if (!resource) {
      navigate(PATH_PAGE.page404);
    }
    if (!currenciesLoading) {
      handleGetPayments();
      handleGetProduct();
    }
  }, [
    resource,
    currenciesLoading,
    navigate,
    handleGetPayments,
    handleGetProduct,
  ]);

  useEffect(() => {
    const isProductReady = Boolean(store.product && !store.loading);
    if (
      !isPlacementsLoaded.current &&
      state.product &&
      isProductReady &&
      !isEmpty(options.payments)
    ) {
      isPlacementsLoaded.current = true;
      const messages = handleGetPaymentMessages(
        options.payments,
        state.product
      );
      setMessagingPlacements(messages);
    }
  }, [
    options.payments,
    state.product,
    store,
    handleGetPaymentMessages,
    setMessagingPlacements,
  ]);

  const handleGetNavigation = useCallback(
    async (id) => {
      if (!id) {
        return new Promise((res) => {
          res(DEFAULT_NAVIGATION_LINK);
        });
      }
      return cacheCallback(navigations.current, id, handleGetNavigationById);
    },
    [navigations]
  );

  const handleGetNavigations = useCallback(async (ids) => {
    if (!ids || isEmpty(ids)) {
      return new Promise((res) => {
        res([
          { ...DEFAULT_NAVIGATION_LINK, id: 1 },
          { ...DEFAULT_NAVIGATION_LINK, id: 2 },
        ]);
      });
    }
    return getNavigationLinkByIds(ids);
  }, []);

  const handleCreateCustomer = useCallback(
    (email) => handleCreateCustomerByEmail(email),
    []
  );

  const handleGetStoreInformation = useCallback(async () => {
    return cacheCallback(
      storeInformation.current,
      "store",
      handleGetCompleteStoreInformation
    );
  }, []);

  const handleNavigationClick = useCallback(
    (type, resource) => {
      if (type) {
        const path = buildNavigationPath(type, resource);
        if (path) {
          navigate(path);
        } else {
          navigate(PATH_PAGE.page404);
        }
      }
    },
    [navigate]
  );

  const handleChangeCurrency = useCallback(
    (value) => {
      dispatch(clearCart());
      dispatch(clearRecentlyViewedProducts());
      dispatch(updateCurency(value));
    },
    [dispatch]
  );

  const handleIsAddedToCart = useCallback(
    (id, variantId) => {
      return isAddedToCart(cart, id, variantId);
    },
    [cart]
  );

  const handleAddToCart = useCallback(
    (orderProduct) => {
      const payload = { currency: storeCurrency?.code, product: orderProduct };
      dispatch(addToCart(payload));
    },
    [storeCurrency, dispatch]
  );

  const handleRemoveFromCart = useCallback(
    (id, variantId) => {
      const payload = { currency: storeCurrency?.code, id, variantId };
      dispatch(removeFromCart(payload));
    },
    [storeCurrency, dispatch]
  );

  const handleBuyNow = useCallback(
    (orderProduct) => {
      const payload = { currency: storeCurrency?.code, product: orderProduct };
      dispatch(addToCart(payload));
      navigate(PATH_PAGE.checkout);
    },
    [storeCurrency, dispatch, navigate]
  );

  const ProductPageMemo = useMemo(() => {
    const theme = config?.theme;
    const logo = config?.assets;

    const product = store.product;
    const filteredRecent = filter(
      recentlyViewedProducts,
      (_product) => _product.id !== product?.id
    );
    const isViewReady = Boolean(!store.loading && product && theme);

    return (
      isViewReady && (
        <>
          <ClearpayScript options={options.payments}>
            {KlarnaMessagingScriptMemo}
            <ProductPageTemplate
              context={{ capability, pagePath: resource }}
              logo={logo}
              config={theme}
              currency={currency}
              currencies={currencies}
              currenciesLoading={currenciesLoading}
              product={product}
              recentlyViewedProducts={filteredRecent}
              cartTotal={cartTotal}
              messagingPlacements={messagingPlacements}
              handleProductChange={handleProductChange}
              handleIsAddedToCart={handleIsAddedToCart}
              handleBuyNow={handleBuyNow}
              handleAddToCart={handleAddToCart}
              handleRemoveFromCart={handleRemoveFromCart}
              handleNavigationClick={handleNavigationClick}
              handleChangeCurrency={handleChangeCurrency}
              handleGetNavigation={handleGetNavigation}
              handleGetNavigations={handleGetNavigations}
              handleGetStoreInformation={handleGetStoreInformation}
              handleCreateCustomer={handleCreateCustomer}
            />
          </ClearpayScript>
        </>
      )
    );
  }, [
    config,
    currency,
    currencies,
    currenciesLoading,
    capability,
    resource,
    store,
    options.payments,
    recentlyViewedProducts,
    cartTotal,
    messagingPlacements,
    KlarnaMessagingScriptMemo,
    handleProductChange,
    handleIsAddedToCart,
    handleBuyNow,
    handleAddToCart,
    handleRemoveFromCart,
    handleNavigationClick,
    handleChangeCurrency,
    handleGetNavigation,
    handleGetNavigations,
    handleGetStoreInformation,
    handleCreateCustomer,
  ]);

  return <GoogleAnalyticsTrackPage>{ProductPageMemo}</GoogleAnalyticsTrackPage>;
};
