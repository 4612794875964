import React, { useCallback, useMemo, useRef } from "react";
import { Error500PageTemplate } from "@iamilyas/store-template-library";
import { useNavigate } from "react-router-dom";
import useConfig from "src/hooks/useConfig";
import {
  handleCreateCustomerByEmail,
  handleGetNavigationById,
  handleGetCompleteStoreInformation,
  getNavigationLinkByIds,
} from "src/service/host";
import { buildNavigationPath } from "src/routes/paths";
import { cacheCallback } from "src/utils/requests";
import { getCartCount } from "src/utils/cart";
import { isEmpty } from "lodash";
import GoogleAnalyticsTrackPage from "src/components/GoogleAnalyticsTrackPage";
import { updateCurency } from "src/redux/slices/store";
import { chooseCurrency, filterCartByCurrency } from "src/utils/currency";
import {
  clearCart,
  clearRecentlyViewedProducts,
} from "src/redux/slices/product";
import { DEFAULT_NAVIGATION_LINK } from "src/utils/defaults";
import { useDispatch, useSelector } from "../redux/store";

export default function Page500() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { cart: cartStore } = useSelector((state) => state.product);
  const {
    currency: prevSelectedCurrency,
    options: { currencies },
    loading: { currencies: currenciesLoading },
  } = useSelector((state) => state.store);

  // CONFIG
  const { config } = useConfig();

  const capability = config?.capability;
  const storeCurrency = config?.currency;
  const currency = chooseCurrency(
    prevSelectedCurrency,
    storeCurrency,
    capability,
    currencies
  );
  const cart = filterCartByCurrency(currency, cartStore);

  // CACHE
  const navigations = useRef([]);
  const storeInformation = useRef([]);

  // DATA
  const cartTotal = getCartCount(cart);

  const handleGetNavigation = useCallback(
    async (id) => {
      if (!id) {
        return new Promise((res) => {
          res(DEFAULT_NAVIGATION_LINK);
        });
      }
      return cacheCallback(navigations.current, id, handleGetNavigationById);
    },
    [navigations]
  );

  const handleGetNavigations = useCallback(async (ids) => {
    if (!ids || isEmpty(ids)) {
      return new Promise((res) => {
        res([
          { ...DEFAULT_NAVIGATION_LINK, id: 1 },
          { ...DEFAULT_NAVIGATION_LINK, id: 2 },
        ]);
      });
    }
    return getNavigationLinkByIds(ids);
  }, []);

  const handleGetStoreInformation = useCallback(async () => {
    return cacheCallback(
      storeInformation.current,
      "store",
      handleGetCompleteStoreInformation
    );
  }, []);

  const handleNavigationClick = useCallback(
    (type, resource) => {
      if (type) {
        const path = buildNavigationPath(type, resource);
        if (path) {
          navigate(path);
        }
      }
    },
    [navigate]
  );

  const handleChangeCurrency = useCallback(
    (value) => {
      dispatch(clearCart());
      dispatch(clearRecentlyViewedProducts());
      dispatch(updateCurency(value));
    },
    [dispatch]
  );

  const handleCreateCustomer = useCallback(
    (email) => handleCreateCustomerByEmail(email),
    []
  );

  const Page500Memo = useMemo(() => {
    const theme = config?.theme;
    const logo = config?.assets;
    return (
      theme && (
        <Error500PageTemplate
          context={{ capability }}
          logo={logo}
          currency={currency}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          config={theme}
          cartTotal={cartTotal}
          handleNavigationClick={handleNavigationClick}
          handleChangeCurrency={handleChangeCurrency}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        />
      )
    );
  }, [
    config,
    cartTotal,
    currency,
    currencies,
    currenciesLoading,
    capability,
    handleGetNavigation,
    handleGetNavigations,
    handleGetStoreInformation,
    handleNavigationClick,
    handleChangeCurrency,
    handleCreateCustomer,
  ]);

  return <GoogleAnalyticsTrackPage>{Page500Memo}</GoogleAnalyticsTrackPage>;
}
