import { mapProductsToRequest } from "src/utils/requests";
import axios from "../utils/axios";

// ----------------------------------------------------------------------

// SHIPPING
export async function handleGetShippingOptions(country, cart, currency) {
  const response = await axios.post(`/host/options/shipping`, {
    products: mapProductsToRequest(cart),
    country,
    currency,
  });
  return response.data;
}

export async function handleGetShippingCountries() {
  return axios.get(`/host/options/shipping/countries`);
}

// ----------------------------------------------------------------------

// PAYMENTS
export async function handleGetPaymentOptions() {
  return axios.get(`/host/options/payments`);
}

// ----------------------------------------------------------------------

// DISCOUNT
export async function handleValidateDiscountCode(code, cart, currency, email) {
  const response = await axios.post(`/host/discounts/validate`, {
    currency,
    discountCode: code,
    email,
    products: mapProductsToRequest(cart),
  });
  return response.data;
}

// CHECKOUT

export const CHECKOUT_SUCCEEDED = "SUCCESS";
export const CHECKOUT_FAILED = "FAIL";

// A BE ENDPOINT TO MAINTAIN SOURCE OF TRUTH FOR DATA. WE DO NOT USE FE CALCULATION OTHER THAN READ-ONLY.
// CALCULATION FOR PAYMENT WILL ALWAYS COME FROM BE
export async function getCheckoutSummary(request) {
  const response = await axios.post(`/host/checkout/summary`, request);
  return response.data;
}

export function handleStartCheckout(currencyCode, form, cart) {
  return axios.post(`/host/checkout`, {
    currency: currencyCode,
    ...form,
    products: mapProductsToRequest(cart),
  });
}

export function handleCreateCheckoutSession(orderId, session) {
  return axios.put(`/host/checkout/${orderId}/session/${session}`);
}

export function handleCompleteCheckout(
  orderId,
  paymentProviderId,
  status,
  errorMessage,
  summary
) {
  return axios.put(`/host/checkout/${orderId}/complete`, {
    paymentProviderId,
    status,
    failureReason: errorMessage,
    purchaseSummary: {
      // (AMOUNT) ONLY NEED ONE OR THE OTHER NOT BOTH
      amountInMajorUnits: summary?.amountInMajorUnits,
      amountInMinorUnits: summary?.amountInMinorUnits,
      purchaseCurrencyCode: summary?.currency,
      purchaseCountry: summary?.country,
    },
  });
}

// Check after payment has completed that the orderid and payment intent match
export const verifyCheckoutPaymentSession = async (orderId, paymentIntent) => {
  const repsonse = await axios.get(
    `/host/checkout/${orderId}/verify/${paymentIntent}`
  );
  return repsonse.data;
};
