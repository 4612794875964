import React, { useCallback, useRef, useMemo } from "react";
import { HomePageTemplate, HOME_PATH } from "@iamilyas/store-template-library";
import { useNavigate } from "react-router-dom";
import { getCartCount, isAddedToCart } from "src/utils/cart";
import { cacheCallback } from "src/utils/requests";
import useConfig from "src/hooks/useConfig";
import { isEmpty } from "lodash";
import { buildNavigationPath, PATH_PAGE } from "src/routes/paths";
import {
  handleCreateCustomerByEmail,
  handleGetNavigationById,
  handleGetCompleteStoreInformation,
  getNavigationLinkByIds,
} from "src/service/host";
import GoogleAnalyticsTrackPage from "src/components/GoogleAnalyticsTrackPage";
import {
  handleGetCollectionProductsByResource,
  handleGetCollectionsByResource,
  handleGetProductByResource,
} from "src/service/product";
import { updateCurency } from "src/redux/slices/store";
import { chooseCurrency, filterCartByCurrency } from "src/utils/currency";
import {
  DEFAULT_NAVIGATION_LINK,
  defaultEmptyProduct,
  getDefaultCollections,
  getDefaultProducts,
} from "src/utils/defaults";
import {
  addToCart,
  clearCart,
  clearRecentlyViewedProducts,
  removeFromCart,
} from "../redux/slices/product";
import { useDispatch, useSelector } from "../redux/store";

export default function HomePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // STATE
  const { config } = useConfig();
  const {
    currency: prevSelectedCurrency,
    options: { currencies },
    loading: { currencies: currenciesLoading },
  } = useSelector((state) => state.store);
  const { cart: cartStore } = useSelector((state) => state.product);
  const storeCurrency = config?.currency;
  const storeCurrencyCode = storeCurrency?.code;
  const capability = config?.capability;
  const currencyStore = chooseCurrency(
    prevSelectedCurrency,
    storeCurrency,
    capability,
    currencies
  );
  const currencyCode = currencyStore?.code;
  const cart = filterCartByCurrency(currencyStore, cartStore);

  // DATA
  const cartTotal = getCartCount(cart);

  // CACHE
  const navigations = useRef([]);
  const storeInformation = useRef([]);

  const handleGetNavigation = useCallback(
    async (id) => {
      if (!id) {
        return new Promise((res) => {
          res(DEFAULT_NAVIGATION_LINK);
        });
      }
      return cacheCallback(navigations.current, id, handleGetNavigationById);
    },
    [navigations]
  );

  const handleGetNavigations = useCallback(async (ids) => {
    if (!ids || isEmpty(ids)) {
      return new Promise((res) => {
        res([
          { ...DEFAULT_NAVIGATION_LINK, id: 1 },
          { ...DEFAULT_NAVIGATION_LINK, id: 2 },
        ]);
      });
    }
    return getNavigationLinkByIds(ids);
  }, []);

  const handleGetStoreInformation = useCallback(async () => {
    return cacheCallback(
      storeInformation.current,
      "store",
      handleGetCompleteStoreInformation
    );
  }, []);

  const handleNavigationClick = useCallback(
    (type, resource) => {
      if (type) {
        const path = buildNavigationPath(type, resource);
        if (path) {
          navigate(path);
        } else {
          navigate(PATH_PAGE.page404);
        }
      }
    },
    [navigate]
  );

  const handleChangeCurrency = useCallback(
    (value) => {
      dispatch(clearCart());
      dispatch(clearRecentlyViewedProducts());
      dispatch(updateCurency(value));
    },
    [dispatch]
  );

  const handleCreateCustomer = useCallback(
    (email) => handleCreateCustomerByEmail(email),
    []
  );

  const handleIsAddedToCart = useCallback(
    (id, variantId) => {
      return isAddedToCart(cart, id, variantId);
    },
    [cart]
  );

  const handleAddToCart = useCallback(
    (orderProduct) => {
      const payload = { currency: storeCurrencyCode, product: orderProduct };
      dispatch(addToCart(payload));
    },
    [storeCurrencyCode, dispatch]
  );

  const handleRemoveFromCart = useCallback(
    (id, variantId) => {
      const payload = { currency: storeCurrencyCode, id, variantId };
      dispatch(removeFromCart(payload));
    },
    [storeCurrencyCode, dispatch]
  );

  const handleBuyNow = useCallback(
    (orderProduct) => {
      const payload = { currency: storeCurrencyCode, product: orderProduct };
      dispatch(addToCart(payload));
      navigate(PATH_PAGE.checkout);
    },
    [storeCurrencyCode, dispatch, navigate]
  );

  const handleGetProduct = useCallback(
    async (resource) => {
      const product = defaultEmptyProduct();
      if (!resource) {
        return product;
      }
      try {
        return await handleGetProductByResource(resource, currencyCode);
      } catch {
        return product;
      }
    },
    [currencyCode]
  );

  const handleGetCollectionProducts = useCallback(
    async (resource, numberOfProducts) => {
      const defaultProducts = getDefaultProducts(8);
      if (!resource) {
        return defaultProducts;
      }
      const page = 0;
      const response = await handleGetCollectionProductsByResource(
        resource,
        page,
        numberOfProducts,
        currencyCode
      );
      // If there is a collection but has no products then we will use empty products view
      return response.data;
    },
    [currencyCode]
  );

  const handleGetCollections = useCallback(
    async (resources, numberOfCollections) => {
      const defaultCollections = getDefaultCollections(8);
      if (!resources || isEmpty(resources)) {
        return defaultCollections;
      }
      const page = 0;
      const response = await handleGetCollectionsByResource(
        resources,
        page,
        numberOfCollections
      );
      return response.data;
    },
    []
  );

  const HomePageMemo = useMemo(() => {
    const theme = config?.theme;
    const logo = config?.assets;

    return (
      <HomePageTemplate
        context={{ capability, pagePath: HOME_PATH }}
        logo={logo}
        config={theme}
        currency={currencyStore}
        currencies={currencies}
        currenciesLoading={currenciesLoading}
        cartTotal={cartTotal}
        handleIsAddedToCart={handleIsAddedToCart}
        handleBuyNow={handleBuyNow}
        handleAddToCart={handleAddToCart}
        handleRemoveFromCart={handleRemoveFromCart}
        handleGetProduct={handleGetProduct}
        handleGetCollectionProducts={handleGetCollectionProducts}
        handleGetCollections={handleGetCollections}
        handleNavigationClick={handleNavigationClick}
        handleChangeCurrency={handleChangeCurrency}
        handleGetNavigation={handleGetNavigation}
        handleGetNavigations={handleGetNavigations}
        handleGetStoreInformation={handleGetStoreInformation}
        handleCreateCustomer={handleCreateCustomer}
      />
    );
  }, [
    config,
    currencyStore,
    currencies,
    currenciesLoading,
    capability,
    cartTotal,
    handleIsAddedToCart,
    handleBuyNow,
    handleAddToCart,
    handleRemoveFromCart,
    handleGetProduct,
    handleGetCollectionProducts,
    handleGetCollections,
    handleNavigationClick,
    handleChangeCurrency,
    handleGetNavigation,
    handleGetNavigations,
    handleGetStoreInformation,
    handleCreateCustomer,
  ]);

  return <GoogleAnalyticsTrackPage>{HomePageMemo}</GoogleAnalyticsTrackPage>;
}
