import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CollectionListTemplate } from "@iamilyas/store-template-library";
import { useNavigate } from "react-router-dom";
import initial, { INITIAL_PAGE_VALUE, LAZY_PAGE_SIZE } from "src/utils/initial";
import useConfig from "src/hooks/useConfig";
import {
  handleCreateCustomerByEmail,
  handleGetNavigationById,
  handleGetCompleteStoreInformation,
  getNavigationLinkByIds,
} from "src/service/host";
import { handleGetCollectionsByResource } from "src/service/product";
import { buildNavigationPath, PATH_PAGE } from "src/routes/paths";
import { cacheCallback } from "src/utils/requests";
import { isEmpty, upperCase } from "lodash";
import { getCartCount } from "src/utils/cart";
import GoogleAnalyticsTrackPage from "src/components/GoogleAnalyticsTrackPage";
import { updateCurency } from "src/redux/slices/store";
import { chooseCurrency, filterCartByCurrency } from "src/utils/currency";
import {
  clearCart,
  clearRecentlyViewedProducts,
} from "src/redux/slices/product";
import { DEFAULT_NAVIGATION_LINK } from "src/utils/defaults";
import { useDispatch, useSelector } from "../redux/store";

export default function CollectionsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // STATE
  const { config } = useConfig();
  const { cart: cartStore, recentlyViewedProducts } = useSelector(
    (state) => state.product
  );
  const {
    currency: prevSelectedCurrency,
    options: { currencies },
    loading: { currencies: currenciesLoading },
  } = useSelector((state) => state.store);
  const capability = config?.capability;
  const storeCurrency = config?.currency;
  const currencyStore = chooseCurrency(
    prevSelectedCurrency,
    storeCurrency,
    capability,
    currencies
  );
  const cart = filterCartByCurrency(currencyStore, cartStore);

  // DATA
  const pageRequest = useRef(INITIAL_PAGE_VALUE);
  const [store, setStore] = useState({
    collections: initial.pagableData,
    loading: true,
  });
  const [queryParams, setQueryParams] = useState({
    sort: "desc",
    sortBy: "createdAt",
    filterName: "",
  });
  const cartTotal = getCartCount(cart);

  // CACHE
  const navigations = useRef([]);
  const storeInformation = useRef([]);

  const updateStore = useCallback(
    (loading, onGetCollections) => {
      setStore((prev) => {
        return {
          ...prev,
          ...(onGetCollections && {
            collections: onGetCollections(prev.collections),
          }),
          loading,
        };
      });
    },
    [setStore]
  );

  const getCollections = useCallback(
    (page, onSuccess) => {
      const { sort, sortBy, filterName } = queryParams;
      updateStore(true);
      handleGetCollectionsByResource(
        null,
        page,
        LAZY_PAGE_SIZE,
        sortBy,
        upperCase(sort),
        filterName
      )
        .then((response) => {
          onSuccess(response);
        })
        .catch(() => {
          updateStore(false, () => initial.pagableData);
        });
    },
    [queryParams, updateStore]
  );

  const handleGetAllCollections = useCallback(() => {
    getCollections(INITIAL_PAGE_VALUE, (response) => {
      updateStore(false, () => response);
    });
  }, [getCollections, updateStore]);

  const handleGetMoreCollections = useCallback(
    (page) => {
      getCollections(page, (response) => {
        updateStore(false, (prev) => {
          return {
            ...response,
            data: prev.data.concat(response.data),
          };
        });
      });
    },
    [getCollections, updateStore]
  );

  const handleLoadMore = useCallback(() => {
    const nextPage = pageRequest.current + 1;
    pageRequest.current = nextPage;
    handleGetMoreCollections(nextPage);
  }, [handleGetMoreCollections]);

  useEffect(() => {
    if (!currenciesLoading) {
      handleGetAllCollections();
    }
  }, [currenciesLoading, handleGetAllCollections]);

  const resetLoadedPage = useCallback(() => {
    setStore((prev) => {
      return {
        collections: {
          ...prev.collections,
          data: [],
        },
      };
    });
    pageRequest.current = 0;
  }, [setStore]);

  const handleChangeOrder = useCallback(
    (sortBy, sort) => {
      setQueryParams((prevState) => {
        return { ...prevState, sortBy, sort };
      });
      resetLoadedPage();
    },
    [resetLoadedPage, setQueryParams]
  );

  const handleGetNavigation = useCallback(
    async (id) => {
      if (!id) {
        return new Promise((res) => {
          res(DEFAULT_NAVIGATION_LINK);
        });
      }
      return cacheCallback(navigations.current, id, handleGetNavigationById);
    },
    [navigations]
  );

  const handleGetNavigations = useCallback(async (ids) => {
    if (!ids || isEmpty(ids)) {
      return new Promise((res) => {
        res([
          { ...DEFAULT_NAVIGATION_LINK, id: 1 },
          { ...DEFAULT_NAVIGATION_LINK, id: 2 },
        ]);
      });
    }
    return getNavigationLinkByIds(ids);
  }, []);

  const handleGetStoreInformation = useCallback(async () => {
    return cacheCallback(
      storeInformation.current,
      "store",
      handleGetCompleteStoreInformation
    );
  }, []);

  const handleNavigationClick = useCallback(
    (type, resource) => {
      if (type) {
        const path = buildNavigationPath(type, resource);
        if (path) {
          navigate(path);
        } else {
          navigate(PATH_PAGE.page404);
        }
      }
    },
    [navigate]
  );

  const handleChangeCurrency = useCallback(
    (value) => {
      dispatch(clearCart());
      dispatch(clearRecentlyViewedProducts());
      dispatch(updateCurency(value));
    },
    [dispatch]
  );

  const handleCreateCustomer = useCallback(
    (email) => handleCreateCustomerByEmail(email),
    []
  );

  const CollectionsPageMemo = useMemo(() => {
    const collections = store.collections;
    const theme = config?.theme;
    const logo = config?.assets;
    const isViewReady = Boolean(theme && currencyStore);

    return (
      isViewReady && (
        <CollectionListTemplate
          context={{ capability }}
          logo={logo}
          config={theme}
          currency={currencyStore}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          loading={store.loading}
          collections={collections}
          recentlyViewedProducts={recentlyViewedProducts}
          queryParams={queryParams}
          cartTotal={cartTotal}
          handleLoadMore={handleLoadMore}
          handleChangeOrder={handleChangeOrder}
          handleNavigationClick={handleNavigationClick}
          handleChangeCurrency={handleChangeCurrency}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        />
      )
    );
  }, [
    config,
    currencyStore,
    currencies,
    currenciesLoading,
    capability,
    store,
    recentlyViewedProducts,
    cartTotal,
    queryParams,
    handleLoadMore,
    handleChangeOrder,
    handleNavigationClick,
    handleChangeCurrency,
    handleGetNavigation,
    handleGetNavigations,
    handleGetStoreInformation,
    handleCreateCustomer,
  ]);

  return (
    <GoogleAnalyticsTrackPage>{CollectionsPageMemo}</GoogleAnalyticsTrackPage>
  );
}
