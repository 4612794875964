import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CheckoutPageTemplate,
  CHECKOUT_SUCCESS_STATUS,
  CHECKOUT_POST_PAYMENT_FAILURE_STATUS,
} from "@iamilyas/store-template-library";
import { useNavigate } from "react-router-dom";
import useConfig from "src/hooks/useConfig";
import {
  getNavigationLinkByIds,
  handleGetNavigationById,
  handleGetStoreContacts,
} from "src/service/host";
import { buildNavigationPath, PATH_PAGE } from "src/routes/paths";
import { cacheCallback } from "src/utils/requests";
import {
  compact,
  defaultTo,
  findIndex,
  first,
  isEmpty,
  isEqual,
  merge,
} from "lodash";
import { paramCase } from "change-case";
import {
  handleCompleteCheckout,
  handleGetPaymentOptions,
  handleGetShippingCountries,
  handleGetShippingOptions,
  handleStartCheckout,
  handleValidateDiscountCode,
} from "src/service/checkout";
import {
  startInternalPaymentJourney,
  startExternalPaymentJourney,
} from "src/service/payments";
import {
  KLARNA_PAYMENT_PROVIDER_KEY,
  PAYMENT_CANCELLED_STATUS,
  PAYMENT_FAILED_STATUS,
  PAYMENT_SUCCESS_STATUS,
  STRIPE_PAYMENT_PROVIDER_KEY,
} from "src/utils/constant";
import { scrollToTop } from "src/utils/scroll";
import { clearCart } from "src/redux/slices/product";
import KlarnaProvider from "src/components/KlarnaProvider";
import { ClearpayScript } from "src/components/ClearpayScript";
import { StripeProvider } from "src/components/StripeProvider";
import { StripeExpressProvider } from "src/components/StripeExpressProvider";
import GoogleAnalyticsTrackPage from "src/components/GoogleAnalyticsTrackPage";
import { completeCheckoutEvent } from "src/hooks/useAnalytics";
import { chooseCurrency, filterCartByCurrency } from "src/utils/currency";
import { DEFAULT_NAVIGATION_LINK } from "src/utils/defaults";
import { useSelector, useDispatch } from "../redux/store";

const INITIAL_CHECKOUT_STAGE = {
  loading: false,
  completed: false,
  summary: null,
  status: null,
};

const INITIAL_PROGRESS_STATE = {
  page: null,
  lastPage: false,
  form: null,
  selectedPaymentOptionKey: null,
};

export const INITIAL_CHECKOUT_ERROR = {
  payments: {
    displayPayments: true,
    message: null,
    enable: false,
  },
  information: {
    displayPayments: true,
    message: null,
    enable: false,
  },
};

export const ACTIVE_CHECKOUT_ERROR = {
  displayPayments: true,
  message: null,
  enable: true,
};

export default function CheckoutPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { config } = useConfig();
  const { cart: cartRoot } = useSelector((state) => state.product);
  const {
    currency: prevSelectedCurrency,
    options: { currencies },
    loading: { currencies: currenciesLoading },
  } = useSelector((state) => state.store);

  const capability = config?.capability;
  const storeCurrency = config?.currency;
  const currencyStore = chooseCurrency(
    prevSelectedCurrency,
    storeCurrency,
    capability,
    currencies
  );
  const cart = filterCartByCurrency(currencyStore, cartRoot);

  const initialPaymentOptions = useRef([]);
  const externalCheckoutRef = useRef({
    form: {},
  });

  // STATE
  const [store, setStore] = useState({
    options: {
      countries: [],
      shipping: [],
      payments: [],
    },
    loading: {
      countries: false,
      shipping: false,
      payments: false,
    },
  });
  const [checkoutStage, setCheckoutStage] = useState(INITIAL_CHECKOUT_STAGE);
  const [contacts, setContacts] = useState(false);
  // Check to see if contact job is ran and avoid duplicate invocation
  const contactRan = useRef(false);
  const [hasCheckoutError, setHasCheckoutError] = useState(
    INITIAL_CHECKOUT_ERROR
  );

  // State is updated by the following:
  // - Page listener
  // - Discount listener
  // - Change payment option listener
  // Updated to the current state are based on the checkout progress via the checkout flow.
  // NOTE - This can be partially completed as the flow is updated on each event, NOT ALWAYS at the end.
  const [state, setState] = useState(INITIAL_PROGRESS_STATE);

  // External orders are created via callback. This is a way to hold states between callbacks.
  const externalPaymentOrderRef = useRef(null);
  const externalPaymentClosure = useRef(null);
  // Misc
  const navigations = useRef([]);

  const getPaymentOptionMethod = useCallback((option, paymentKey) => {
    const methods = option.methods;
    if (methods) {
      const found = methods.map((method) => {
        const { id, ...other } = method;
        if (id === paymentKey) {
          return {
            ...option,
            ...other,
          };
        }
        return null;
      });
      const compacted = compact(found);
      if (compacted.length !== 1) {
        return null;
      }
      return first(compacted);
    }
    return null;
  }, []);

  // `paymentKey` CAN BE AN PAYMENT KEY OR THE METHOD ID
  const getPaymentOption = useCallback(
    (paymentKey) => {
      const options = store.options.payments;
      if (!options || isEmpty(options)) {
        return null;
      }
      const found = options.map((option) => {
        if (
          paramCase(defaultTo(option.key, "")) ===
          paramCase(defaultTo(paymentKey, ""))
        ) {
          return option;
        }

        return getPaymentOptionMethod(option, paymentKey);
      });
      const compacted = compact(found);
      if (compacted.length !== 1) {
        return null;
      }
      return first(compacted);
    },
    [store.options.payments, getPaymentOptionMethod]
  );

  const updateStore = useCallback(
    (loadingState, state) => {
      setStore((prev) => {
        return {
          ...prev,
          ...(state && {
            options: {
              ...prev.options,
              ...state,
            },
          }),
          loading: {
            ...prev.loading,
            ...loadingState,
          },
        };
      });
    },
    [setStore]
  );

  const updateState = useCallback(
    (partial) => {
      setState((prev) => {
        return {
          ...prev,
          ...partial,
        };
      });
    },
    [setState]
  );

  const updateHasCheckoutError = useCallback(
    (payments, information) => {
      setHasCheckoutError((prev) => {
        return {
          ...prev,
          ...(payments && { payments }),
          ...(information && { information }),
        };
      });
    },
    [setHasCheckoutError]
  );

  const updatePaymentOption = useCallback(
    (key, state) => {
      setStore((prev) => {
        const update = [...prev.options.payments];
        const index = findIndex(
          update,
          (o) => paramCase(o.key) === paramCase(key)
        );
        if (index < 0) {
          return prev;
        }
        const option = prev.options.payments[index];
        update[index] = {
          ...option,
          ...state,
        };
        return {
          ...prev,
          options: {
            ...prev.options,
            payments: update,
          },
        };
      });
    },
    [setStore]
  );

  const updatePaymentOptionMethod = useCallback(
    (key, id, state) => {
      setStore((prev) => {
        const arr = [...prev.options.payments];
        const index = findIndex(
          arr,
          (o) => paramCase(o.key) === paramCase(key)
        );
        if (index < 0) {
          return prev;
        }
        const options = arr[index];
        const methodIndex = findIndex(options.methods, { id });
        if (methodIndex < 0) {
          return prev;
        }
        const update = {
          ...options.methods[methodIndex],
          ...state,
        };
        arr[index].methods[methodIndex] = update;
        return {
          ...prev,
          options: {
            ...prev.options,
            payments: arr,
          },
        };
      });
    },
    [setStore]
  );

  const resetPaymentOptions = useCallback(() => {
    updateStore(
      { payments: false },
      {
        payments: initialPaymentOptions.current,
      }
    );
  }, [updateStore]);

  const handleGetTenantContactInfo = useCallback(() => {
    if (contactRan.current) {
      return;
    }
    contactRan.current = true;
    handleGetStoreContacts()
      .then((response) => {
        setContacts(response);
      })
      .catch(() => {
        setContacts(null);
      });
  }, []);

  const handleGetShipping = useCallback(
    (country) => {
      updateStore({ shipping: true });
      handleGetShippingOptions(country, cart, currencyStore?.code)
        .then((resp) => {
          updateStore({ shipping: false }, { shipping: resp });
        })
        .catch(() => {
          updateStore({ shipping: false }, { shipping: [] });
        });
    },
    [cart, currencyStore, updateStore]
  );

  const handleGetInitalOptions = useCallback(() => {
    const isNotReady =
      !isEmpty(store.options.payments) ||
      store.options.payments === null ||
      !isEmpty(store.options.countries) ||
      store.options.countries === null ||
      store.loading.payments ||
      store.loading.countries;
    if (isNotReady) {
      return;
    }
    updateStore({ countries: true, payments: true });
    Promise.all([handleGetShippingCountries(), handleGetPaymentOptions()])
      .then((values) => {
        const paymentOptions = values[1].data.map((option) => {
          return {
            ...option,
            loading: false,
            enabled: true,
          };
        });
        // Store this so we can reset at a later point after modifications
        initialPaymentOptions.current = paymentOptions;
        updateStore(
          { countries: false, payments: false },
          {
            countries: values[0].data,
            payments: paymentOptions,
          }
        );
      })
      .catch(() => {
        updateStore(
          { countries: false, payments: false },
          {
            countries: null,
            payments: null,
          }
        );
        // TODO handle error
      });
  }, [store, updateStore]);

  const handleValidateDiscount = useCallback(
    (code, email) => {
      return handleValidateDiscountCode(code, cart, currencyStore?.code, email);
    },
    [cart, currencyStore]
  );

  const handleCheckoutStart = useCallback(
    async (form) => {
      const response = await handleStartCheckout(
        currencyStore?.code,
        form,
        cart
      );
      return response.data;
    },
    [cart, currencyStore]
  );

  const handleSuccesfulPayment = useCallback(
    (orderId, paymentId, summary) => {
      setCheckoutStage({
        status: CHECKOUT_SUCCESS_STATUS,
        summary: null,
        completed: true,
        loading: true,
      });
      handleCompleteCheckout(
        orderId, // BE ORDER ID
        paymentId, // PAYMENT PROVIDER ID
        PAYMENT_SUCCESS_STATUS,
        null,
        summary
      )
        .then((response) => {
          const orderSummary = response.data;
          completeCheckoutEvent(orderSummary);
          setCheckoutStage((prev) => {
            return {
              ...prev,
              loading: false,
              summary: orderSummary,
              status: CHECKOUT_SUCCESS_STATUS,
            };
          });
        })
        .catch(() => {
          setCheckoutStage((prev) => {
            return {
              ...prev,
              loading: false,
              status: CHECKOUT_POST_PAYMENT_FAILURE_STATUS,
              summary: {
                orderId,
                supportEmail: contacts?.support,
              },
            };
          });
        })
        .finally(() => {
          scrollToTop();
          dispatch(clearCart());
        });
    },
    [dispatch, contacts]
  );

  const handleFailedPayment = useCallback(
    (orderId, paymentId, errorMessage) => {
      updateHasCheckoutError(ACTIVE_CHECKOUT_ERROR);
      scrollToTop();
      if (orderId) {
        handleCompleteCheckout(
          orderId, // BE ORDER ID
          paymentId, // PAYMENT PROVIDER ID
          PAYMENT_FAILED_STATUS,
          errorMessage
        );
      }
    },
    [updateHasCheckoutError]
  );

  const handleCancelPayment = useCallback((orderId, paymentId) => {
    handleCompleteCheckout(
      orderId, // BE ORDER ID
      paymentId, // PAYMENT PROVIDER ID
      PAYMENT_CANCELLED_STATUS,
      null
    );
  }, []);

  const handleDisablePayment = useCallback(
    (key) => {
      updatePaymentOption(key, { loading: false, enabled: false });
    },
    [updatePaymentOption]
  );

  const handleDisablePaymentMethod = useCallback(
    (key) => {
      updatePaymentOptionMethod(key, { loading: false, enabled: false });
    },
    [updatePaymentOptionMethod]
  );

  const invokeExternalPaymentTearDown = useCallback(async () => {
    try {
      if (externalPaymentClosure.current) {
        (await externalPaymentClosure.current).apply();
      }
    } catch {
      externalPaymentClosure.current = null;
    }
  }, []);

  const updateExternalPaymentClosure = useCallback((value) => {
    externalPaymentClosure.current = value;
  }, []);

  const handleExternalCheckoutPaymentStart = useCallback(
    async (form, paymentOptionId) => {
      // INVESTIGATE WHY DISCOUNT ISNT APPLIED HERE
      const request = {
        ...form,
        discountCode:
          isEmpty(form?.discountCode) || !form?.discountValid
            ? null
            : form?.discountCode,
        paymentOption: paymentOptionId,
      };
      const response = await handleCheckoutStart(request);
      externalPaymentOrderRef.current = response;
      return response;
    },
    [handleCheckoutStart]
  );

  // CHECKOUT JOURNEY FOR `EXTERNAL` PAYMENT TYPE
  const handleExternalCheckout = useCallback(
    (form, payment, container) => {
      try {
        const { id: paymentOptionId, key, type, auth } = payment;
        if (paramCase(defaultTo(type, "")) === "external") {
          const tearDown = startExternalPaymentJourney(
            form,
            currencyStore?.code,
            auth,
            key,
            container,
            // ON PAYMENT START
            async () =>
              handleExternalCheckoutPaymentStart(
                merge(form, externalCheckoutRef.current.form),
                paymentOptionId
              ),
            // ON PAYMENT APPROVED
            (paymentId, summary) =>
              handleSuccesfulPayment(
                externalPaymentOrderRef.current?.id,
                paymentId,
                summary
              ),
            // ON PAYMENT FAILURE
            (paymentId, errorMessage) => {
              invokeExternalPaymentTearDown();
              handleFailedPayment(
                externalPaymentOrderRef.current?.id,
                paymentId,
                errorMessage
              );
            },
            // ON PAYMENT CANCELLED
            (paymentId) =>
              handleCancelPayment(
                externalPaymentOrderRef.current?.id,
                paymentId
              ),
            // ON INIT FAILURE
            () => {
              updateHasCheckoutError(ACTIVE_CHECKOUT_ERROR);
              scrollToTop();
            }
          );
          updateExternalPaymentClosure(tearDown);
        }
      } catch {
        updateHasCheckoutError(ACTIVE_CHECKOUT_ERROR);
        scrollToTop();
      }
    },
    [
      currencyStore,
      handleExternalCheckoutPaymentStart,
      handleSuccesfulPayment,
      handleFailedPayment,
      handleCancelPayment,
      updateHasCheckoutError,
      invokeExternalPaymentTearDown,
      updateExternalPaymentClosure,
    ]
  );

  // VALIDATION AND CHECKS FOR `handleExternalCheckout` PROCESS
  const handleExternalCheckoutProcess = useCallback(
    (form, paymentKey, container) => {
      if (!form) {
        // If form is missing, this will be thrown in the `handleCheckoutFailure` event listener. Not needed to handle twice
        return;
      }
      try {
        updateHasCheckoutError(INITIAL_CHECKOUT_ERROR.payments);
        invokeExternalPaymentTearDown();
        const found = getPaymentOption(paymentKey);
        if (!found) {
          updateHasCheckoutError(ACTIVE_CHECKOUT_ERROR);
          scrollToTop();
          return;
        }
        handleExternalCheckout(form, found, container);
      } catch {
        updateHasCheckoutError(ACTIVE_CHECKOUT_ERROR);
        scrollToTop();
      }
    },
    [
      getPaymentOption,
      invokeExternalPaymentTearDown,
      handleExternalCheckout,
      updateHasCheckoutError,
    ]
  );

  const handleChangePaymentOption = useCallback(
    (form, paymentKey, container) => {
      handleExternalCheckoutProcess(form, paymentKey, container);
      updateState({ selectedPaymentOptionKey: paymentKey });
    },
    [handleExternalCheckoutProcess, updateState]
  );

  // CHECKOUT JOURNEY FOR `INTERNAL` PAYMENT TYPE
  const handleInternalCheckout = useCallback(
    async (form, paymentsContext) => {
      updateHasCheckoutError(INITIAL_CHECKOUT_ERROR.payments);
      const { paymentOption: paymentKey } = form;
      const found = getPaymentOption(paymentKey);
      if (!found) {
        updateHasCheckoutError(ACTIVE_CHECKOUT_ERROR);
        scrollToTop();
        return;
      }
      if (form) {
        try {
          const request = {
            ...form,
            discountCode:
              isEmpty(form?.discountCode) || !form?.discountValid
                ? null
                : form?.discountCode,
            paymentOption: found?.id,
            session: found?.session,
          };
          const response = await handleCheckoutStart(request);
          const context = {
            ...response,
            paymentsContext,
            paymentOption: found,
          };
          await startInternalPaymentJourney(
            form,
            context,
            handleSuccesfulPayment,
            handleFailedPayment,
            handleCancelPayment,
            handleDisablePayment
          );
        } catch (e) {
          updateHasCheckoutError(ACTIVE_CHECKOUT_ERROR);
          scrollToTop();
        }
      }
    },
    [
      getPaymentOption,
      handleCheckoutStart,
      handleSuccesfulPayment,
      handleFailedPayment,
      handleCancelPayment,
      handleDisablePayment,
      updateHasCheckoutError,
    ]
  );

  const handleCheckoutFailure = useCallback(
    (error) => {
      updateHasCheckoutError({ enable: true, message: error });
      scrollToTop();
    },
    [updateHasCheckoutError]
  );

  const handleInformationCheckoutFailure = useCallback(
    (obj) => {
      updateHasCheckoutError(null, obj);
      scrollToTop();
    },
    [updateHasCheckoutError]
  );

  const handleChangedPageListener = useCallback(
    (form, page, lastPage, isFirstPage) => {
      if (!isEqual(hasCheckoutError, INITIAL_CHECKOUT_ERROR)) {
        setHasCheckoutError(INITIAL_CHECKOUT_ERROR);
      }
      updateState({
        form,
        page,
        lastPage,
        // Always reset key internally
        selectedPaymentOptionKey:
          INITIAL_PROGRESS_STATE.selectedPaymentOptionKey,
      });
      if (isFirstPage) {
        resetPaymentOptions();
      }
      invokeExternalPaymentTearDown();
    },
    [
      hasCheckoutError,
      invokeExternalPaymentTearDown,
      updateState,
      setHasCheckoutError,
      resetPaymentOptions,
    ]
  );

  // EVENT LISTENERS
  const handleChangedDiscountListener = useCallback(
    (form) => {
      updateState({ form });
      // Bug fix - Form is looses it's updated reference in the `onPaymentStart` callback. Using ref allows to keep fresh form ref.
      externalCheckoutRef.current.form = form;
    },
    [updateState]
  );

  // COMMON
  const handleGetNavigation = useCallback(
    async (id) => {
      if (!id) {
        return new Promise((res) => {
          res(DEFAULT_NAVIGATION_LINK);
        });
      }
      return cacheCallback(navigations.current, id, handleGetNavigationById);
    },
    [navigations]
  );

  const handleGetNavigations = useCallback(async (ids) => {
    if (!ids || isEmpty(ids)) {
      return new Promise((res) => {
        res([
          { ...DEFAULT_NAVIGATION_LINK, id: 1 },
          { ...DEFAULT_NAVIGATION_LINK, id: 2 },
        ]);
      });
    }
    return getNavigationLinkByIds(ids);
  }, []);

  const handleNavigationClick = useCallback(
    (type, resource) => {
      if (type) {
        const path = buildNavigationPath(type, resource);
        if (path) {
          navigate(path);
        } else {
          navigate(PATH_PAGE.page404);
        }
      }
    },
    [navigate]
  );

  // Check if cart is empty
  useEffect(() => {
    if (!currenciesLoading && !checkoutStage.completed && isEmpty(cart)) {
      navigate(PATH_PAGE.home);
    }
  }, [checkoutStage.completed, currenciesLoading, cart, navigate]);

  // Get initial options
  useEffect(() => {
    if (!currenciesLoading && !isEmpty(cart)) {
      handleGetInitalOptions();
    }
  }, [currenciesLoading, cart, handleGetInitalOptions]);

  // Get tenant contact info
  useEffect(() => {
    if (!currenciesLoading && !isEmpty(cart)) {
      handleGetTenantContactInfo();
    }
  }, [currenciesLoading, cart, handleGetTenantContactInfo]);

  const CheckoutPageMemo = useMemo(() => {
    const theme = config?.theme;
    const logo = config?.assets;
    // Store currency not the selected currency. This is the currency all payments will be made in
    const currency = {
      symbol: currencyStore?.symbol,
      code: currencyStore?.code,
    };
    const options = store.options;

    const isViewReady = Boolean(
      theme && currency && !currenciesLoading && config
    );
    const isPaymentsLoading = store.loading.payments;
    const klarna = getPaymentOption(KLARNA_PAYMENT_PROVIDER_KEY);
    const stripe = getPaymentOption(STRIPE_PAYMENT_PROVIDER_KEY);

    const paymentProviderContext = {
      form: state.form,
      isLastPage: state.lastPage,
      selectedKey: state.selectedPaymentOptionKey,
      currency,
      cart,
    };

    return (
      isViewReady && (
        <>
          <StripeProvider
            loading={isPaymentsLoading}
            stripe={stripe}
            context={paymentProviderContext}
            updatePaymentOption={updatePaymentOption}
            handleDisablePaymentOption={handleDisablePayment}
          >
            <StripeExpressProvider
              loading={isPaymentsLoading}
              stripe={stripe}
              context={paymentProviderContext}
              handleCheckoutFailure={handleInformationCheckoutFailure}
              updatePaymentOption={updatePaymentOption}
              handleDisablePaymentOption={handleDisablePayment}
            >
              <ClearpayScript options={options.payments}>
                <KlarnaProvider
                  loading={isPaymentsLoading}
                  klarna={klarna}
                  context={paymentProviderContext}
                  updatePaymentOption={updatePaymentOption}
                  updatePaymentOptionMethod={updatePaymentOptionMethod}
                  handleDisablePayment={handleDisablePayment}
                  handleDisablePaymentMethod={handleDisablePaymentMethod}
                >
                  <CheckoutPageTemplate
                    loading={store.loading}
                    logo={logo}
                    config={theme}
                    hasCheckoutError={hasCheckoutError}
                    checkoutStage={checkoutStage}
                    currency={currency}
                    products={cart}
                    paymentMethods={options.payments}
                    shippingOptions={options.shipping}
                    shippingCountries={options.countries}
                    handleLoadShippingOptions={handleGetShipping}
                    // Unused at this moment - Future version can hook into this
                    handleLoadPaymentOptions={() => {}}
                    handleChangePaymentOption={handleChangePaymentOption}
                    handleChangedPage={handleChangedPageListener}
                    handleChangedDiscount={handleChangedDiscountListener}
                    handleCheckoutFailure={handleCheckoutFailure}
                    handleValidateDiscount={handleValidateDiscount}
                    handleCheckout={handleInternalCheckout}
                    handleNavigationClick={handleNavigationClick}
                    handleGetNavigation={handleGetNavigation}
                    handleGetNavigations={handleGetNavigations}
                  />
                </KlarnaProvider>
              </ClearpayScript>
            </StripeExpressProvider>
          </StripeProvider>
        </>
      )
    );
  }, [
    store,
    config,
    cart,
    checkoutStage,
    hasCheckoutError,
    state,
    currencyStore,
    currenciesLoading,
    getPaymentOption,
    handleInternalCheckout,
    handleValidateDiscount,
    handleChangePaymentOption,
    handleChangedPageListener,
    handleChangedDiscountListener,
    handleCheckoutFailure,
    handleInformationCheckoutFailure,
    handleNavigationClick,
    handleGetShipping,
    handleGetNavigation,
    handleGetNavigations,
    updatePaymentOption,
    updatePaymentOptionMethod,
    handleDisablePayment,
    handleDisablePaymentMethod,
  ]);

  return (
    <GoogleAnalyticsTrackPage
      isCheckoutPage
      currency={storeCurrency?.code}
      cart={cart}
    >
      {CheckoutPageMemo}
    </GoogleAnalyticsTrackPage>
  );
}
