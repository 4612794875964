import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef } from "react";
import { startCheckoutEvent, viewCartEvent } from "src/hooks/useAnalytics";
import track from "src/utils/analytics";

export default function GoogleAnalyticsTrackPage({
  currency,
  isCartPage = false,
  isCheckoutPage = false,
  cart = [],
  children,
}) {
  const trackPath = useRef(null);

  const handleAdditionalEvents = useCallback(() => {
    if (!currency || isEmpty(cart)) {
      return;
    }

    if (isCartPage) {
      viewCartEvent(currency, cart);
    }

    if (isCheckoutPage) {
      startCheckoutEvent(currency, cart);
    }
  }, [currency, cart, isCartPage, isCheckoutPage]);

  useEffect(() => {
    if (trackPath.current === window.location.pathname || !window.gtag) {
      return;
    }

    trackPath.current = window.location.pathname;
    track.pageview({
      page_path: window.location.pathname,
    });

    handleAdditionalEvents();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.gtag, handleAdditionalEvents]);

  return children;
}
