import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ProductsCollectionTemplate } from "@iamilyas/store-template-library";
import { useNavigate, useParams } from "react-router-dom";
import useConfig from "src/hooks/useConfig";
import {
  handleCreateCustomerByEmail,
  handleGetNavigationById,
  handleGetCompleteStoreInformation,
  getNavigationLinkByIds,
} from "src/service/host";
import {
  handleGetCollectionProductsByResource,
  handleGetCollectionByResource,
  handleGetCollectionFiltersByResource,
} from "src/service/product";
import { buildNavigationPath, PATH_PAGE } from "src/routes/paths";
import { cacheCallback } from "src/utils/requests";
import { isEmpty, upperCase } from "lodash";
import { getCartCount } from "src/utils/cart";
import initial, { INITIAL_PAGE_VALUE, LAZY_PAGE_SIZE } from "src/utils/initial";
import GoogleAnalyticsTrackPage from "src/components/GoogleAnalyticsTrackPage";
import { updateCurency } from "src/redux/slices/store";
import { chooseCurrency, filterCartByCurrency } from "src/utils/currency";
import {
  clearCart,
  clearRecentlyViewedProducts,
} from "src/redux/slices/product";
import { DEFAULT_NAVIGATION_LINK } from "src/utils/defaults";
import { useDispatch, useSelector } from "../redux/store";

const INITIAL_FILTERS_STATE = {
  loading: false,
  data: null,
};

const INITIAL_STORE = {
  products: initial.pagableData,
  loading: true,
};

const INITIAL_LOADED_VALUES = {
  // Collection name
  name: null,
  // Selected currency code
  currency: null,
};

export default function CollectionPage() {
  const { name } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadedValues = useRef(INITIAL_LOADED_VALUES);

  // STATE
  const { config } = useConfig();
  const {
    currency: prevSelectedCurrency,
    options: { currencies },
    loading: { currencies: currenciesLoading },
  } = useSelector((state) => state.store);
  const { cart: cartStore, recentlyViewedProducts } = useSelector(
    (state) => state.product
  );

  const capability = config?.capability;
  const storeCurrency = config?.currency;
  const currencyStore = chooseCurrency(
    prevSelectedCurrency,
    storeCurrency,
    capability,
    currencies
  );
  const currencyCode = currencyStore?.code;

  const cart = filterCartByCurrency(currencyStore, cartStore);

  // DATA
  const pageRequest = useRef(INITIAL_PAGE_VALUE);
  const [collectionStore, setCollectionStore] = useState({
    loading: true,
    data: null,
  });
  const [store, setStore] = useState(INITIAL_STORE);

  // FILTERS
  const [filters, setFilters] = useState(INITIAL_FILTERS_STATE);

  const [queryParams, setQueryParams] = useState({
    sort: "desc",
    sortBy: "createdAt",
    filterName: "",
    filters: null,
  });

  // MISC
  const cartTotal = getCartCount(cart);

  // CACHE
  const navigations = useRef([]);
  const storeInformation = useRef([]);

  const updateStore = useCallback(
    (loading, onGetProducts) => {
      setStore((prev) => {
        return {
          ...prev,
          ...(onGetProducts && { products: onGetProducts(prev.products) }),
          loading,
        };
      });
    },
    [setStore]
  );

  const updateCollection = useCallback(
    (loading, data) => {
      setCollectionStore((prev) => {
        return {
          ...prev,
          ...(data && { data }),
          loading,
        };
      });
    },
    [setCollectionStore]
  );

  const getProducts = useCallback(
    (page, onSuccess) => {
      const resource = collectionStore.data?.resource;
      const { sort, sortBy, filterName, filters } = queryParams;
      handleGetCollectionProductsByResource(
        resource,
        page,
        LAZY_PAGE_SIZE,
        currencyCode,
        sortBy,
        upperCase(sort),
        filterName,
        filters
      )
        .then((response) => {
          onSuccess(response);
        })
        .catch(() => {
          updateStore(false, () => initial.pagableData);
        });
    },
    [queryParams, updateStore, collectionStore, currencyCode]
  );

  const handleGetAllProducts = useCallback(() => {
    if (collectionStore.data && !collectionStore.loading) {
      updateStore(true);
      getProducts(INITIAL_PAGE_VALUE, (response) => {
        updateStore(false, () => response);
      });
    }
  }, [collectionStore, getProducts, updateStore]);

  const handleGetMoreProducts = useCallback(
    (page) => {
      if (collectionStore.data && !collectionStore.loading) {
        // Avoid setting loading here as the scroll page will handle loading.
        getProducts(page, (response) => {
          updateStore(false, (prev) => {
            return {
              ...response,
              data: prev.data.concat(response.data),
            };
          });
        });
      }
    },
    [collectionStore, getProducts, updateStore]
  );

  const handleLoadMore = useCallback(() => {
    const nextPage = pageRequest.current + 1;
    pageRequest.current = nextPage;
    handleGetMoreProducts(nextPage);
  }, [handleGetMoreProducts]);

  const handleGetCollection = useCallback(() => {
    updateCollection(true);
    handleGetCollectionByResource(name)
      .then((_collection) => {
        if (!_collection) {
          navigate(PATH_PAGE.page404);
          return;
        }
        updateCollection(false, _collection);
      })
      .catch(() => {
        navigate(PATH_PAGE.page404);
      });
  }, [name, navigate, updateCollection]);

  const handleGetCollectionFilters = useCallback(() => {
    const hasCollectionChanged =
      loadedValues.current.name !== null && loadedValues.current.name !== name;
    const currencyUnchanged = loadedValues.current.currency === currencyCode;
    const isNotReady =
      filters.loading || (filters.data !== null && !hasCollectionChanged);
    if (currencyUnchanged && isNotReady) {
      return;
    }
    loadedValues.current.currency = currencyCode;
    setFilters({ loading: true, data: null });
    handleGetCollectionFiltersByResource(name, currencyCode)
      .then((_filters) => {
        setFilters({ loading: false, data: _filters });
      })
      .catch((e) => {
        console.error(e);
      });
  }, [name, filters.loading, filters.data, setFilters, currencyCode]);

  useEffect(() => {
    if (!currenciesLoading) {
      handleGetAllProducts();
    }
  }, [currenciesLoading, handleGetAllProducts]);

  useEffect(() => {
    if (!currenciesLoading) {
      handleGetCollectionFilters();
    }
  }, [currenciesLoading, handleGetCollectionFilters]);

  useEffect(() => {
    if (!name) {
      navigate(PATH_PAGE.page404);
    }
    handleGetCollection();
  }, [name, navigate, handleGetCollection]);

  // Reset data on exit of page
  useEffect(() => {
    return () => {
      setStore(INITIAL_STORE);
    };
  }, [setStore]);

  // SPA does not call exit if it's the same page so this will detect changes in collection on same page
  useEffect(() => {
    loadedValues.current.name = name;
  }, [name]);

  const handleChangeOrder = useCallback(
    (sortBy, sort) => {
      setQueryParams((prev) => {
        return { ...prev, sortBy, sort };
      });
    },
    [setQueryParams]
  );

  const handleChangeFiltersListener = useCallback(
    (filters) => {
      setQueryParams((prev) => {
        return { ...prev, filters };
      });
    },
    [setQueryParams]
  );

  const handleGetNavigation = useCallback(
    async (id) => {
      if (!id) {
        return new Promise((res) => {
          res(DEFAULT_NAVIGATION_LINK);
        });
      }
      return cacheCallback(navigations.current, id, handleGetNavigationById);
    },
    [navigations]
  );

  const handleChangeCurrency = useCallback(
    (value) => {
      dispatch(clearCart());
      dispatch(clearRecentlyViewedProducts());
      dispatch(updateCurency(value));
    },
    [dispatch]
  );

  const handleGetNavigations = useCallback(async (ids) => {
    if (!ids || isEmpty(ids)) {
      return new Promise((res) => {
        res([
          { ...DEFAULT_NAVIGATION_LINK, id: 1 },
          { ...DEFAULT_NAVIGATION_LINK, id: 2 },
        ]);
      });
    }
    return getNavigationLinkByIds(ids);
  }, []);

  const handleGetStoreInformation = useCallback(async () => {
    return cacheCallback(
      storeInformation.current,
      "store",
      handleGetCompleteStoreInformation
    );
  }, []);

  const handleNavigationClick = useCallback(
    (type, resource) => {
      if (type) {
        const path = buildNavigationPath(type, resource);
        if (path) {
          navigate(path);
        } else {
          navigate(PATH_PAGE.page404);
        }
      }
    },
    [navigate]
  );

  const handleCreateCustomer = useCallback(
    (email) => handleCreateCustomerByEmail(email),
    []
  );

  const CollectionPageMemo = useMemo(() => {
    const collection = collectionStore.data;
    const products = store.products;
    const theme = config?.theme;
    const logo = config?.assets;
    const isViewReady = Boolean(collection && theme && currencyStore);

    return (
      isViewReady && (
        <ProductsCollectionTemplate
          context={{ capability, pagePath: name }}
          logo={logo}
          config={theme}
          currency={currencyStore}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          cartTotal={cartTotal}
          loading={collectionStore.loading || store.loading}
          collection={collection}
          products={products}
          recentlyViewedProducts={recentlyViewedProducts}
          queryParams={queryParams}
          filters={filters}
          handleLoadMore={handleLoadMore}
          handleChangeOrder={handleChangeOrder}
          handleChangeFilters={handleChangeFiltersListener}
          handleNavigationClick={handleNavigationClick}
          handleChangeCurrency={handleChangeCurrency}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        />
      )
    );
  }, [
    name,
    config,
    currencyStore,
    currencies,
    currenciesLoading,
    capability,
    collectionStore,
    store,
    recentlyViewedProducts,
    cartTotal,
    queryParams,
    filters,
    handleLoadMore,
    handleChangeOrder,
    handleChangeFiltersListener,
    handleNavigationClick,
    handleChangeCurrency,
    handleGetNavigation,
    handleGetNavigations,
    handleGetStoreInformation,
    handleCreateCustomer,
  ]);

  return (
    <GoogleAnalyticsTrackPage>{CollectionPageMemo}</GoogleAnalyticsTrackPage>
  );
}
