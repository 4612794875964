import { useCallback, useEffect, useRef } from "react";
import {
  getPaymentIntent,
  getPaymentIntentRequest,
} from "src/service/payment-options/stripe";
import {
  STRIPE_PAYMENT_PROVIDER_KEY,
  isStripeProdiver,
} from "src/utils/constant";
import useDiscount from "src/hooks/useDiscount";
import useStripe from "src/hooks/useStripe";

const INITIAL_STRIPE_STATE = {
  secret: null,
  loading: false,
};

export const StripeProvider = ({
  loading,
  stripe,
  context,
  updatePaymentOption,
  handleDisablePaymentOption,
  children,
}) => {
  const { checkDiscountChanged } = useDiscount();
  const { getStripeClient } = useStripe();
  const stateRef = useRef(INITIAL_STRIPE_STATE);
  const {
    form,
    isLastPage,
    selectedKey,
    currency: { code: currency },
    cart,
  } = context;
  const discountState = checkDiscountChanged(form?.discountCode);
  const enabled = Boolean(stripe !== null && stripe?.enabled && isLastPage);
  const publicKey = stripe?.auth?.publicKey;

  const updateState = useCallback((state) => {
    stateRef.current = {
      ...stateRef.current,
      ...state,
    };
  }, []);

  const hasDiscountChanged = useCallback(() => {
    const isDiscountChanged = discountState.isDiscountChanged;
    // Reset state and try loading intent again if discount changed
    if (isDiscountChanged) {
      updateState(INITIAL_STRIPE_STATE);
    }
  }, [updateState, discountState]);

  const hasPageChanged = useCallback(() => {
    if (!isLastPage && stateRef.current !== INITIAL_STRIPE_STATE) {
      updateState(INITIAL_STRIPE_STATE);
    }
  }, [updateState, isLastPage]);

  // GET PAYMENT INTENT WHICH WILL LIVE FOR THE LIFECYCLE OF THE CHECKOUT, UNLESS DISCOUNT CHANGED
  useEffect(() => {
    hasDiscountChanged();
    hasPageChanged();
    const state = stateRef.current;
    const isNotReady =
      state.secret !== null ||
      state.loading ||
      loading ||
      !enabled ||
      !isStripeProdiver(selectedKey);
    if (isNotReady) {
      return;
    }
    updateState({
      secret: null,
      loading: true,
    });
    updatePaymentOption(STRIPE_PAYMENT_PROVIDER_KEY, {
      element: {
        loading: true,
      },
    });
    const request = getPaymentIntentRequest(form, cart, currency);
    getPaymentIntent(request)
      .then((response) => {
        const { clientSecret: secret } = response;
        const client = getStripeClient(publicKey);
        updateState({
          secret,
          loading: false,
        });
        updatePaymentOption(STRIPE_PAYMENT_PROVIDER_KEY, {
          session: secret, // Used as a payment session for verification at a later point
          element: {
            secret,
            client,
            loading: false,
          },
        });
      })
      .catch((e) => {
        console.error(e);
        handleDisablePaymentOption(STRIPE_PAYMENT_PROVIDER_KEY);
        updateState(INITIAL_STRIPE_STATE);
      });
  }, [
    currency,
    form,
    enabled,
    cart,
    publicKey,
    selectedKey,
    loading,
    updatePaymentOption,
    updateState,
    getStripeClient,
    hasDiscountChanged,
    hasPageChanged,
    handleDisablePaymentOption,
  ]);

  return children;
};
