import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";
import { getCurrencyOptions } from "src/redux/slices/store";
import { handleGetStoreConfig } from "src/service/host";
import {
  cacheConfig,
  getCurrentConfig,
  isActiveConfig,
} from "src/utils/config";
import { useDispatch } from "../redux/store";

// ----------------------------------------------------------------------

const initialState = {
  theme: null,
};

const ConfigContext = createContext(initialState);

ConfigProvider.propTypes = {
  children: PropTypes.node,
};

function ConfigProvider({ children }) {
  const dispatch = useDispatch();
  const [config, setConifg] = useState(null);

  useEffect(() => {
    const initialise = async () => {
      const cache = getCurrentConfig();
      if (cache && isActiveConfig(cache)) {
        setConifg(cache.data);
      } else {
        await handleGetStoreConfig()
          .then((response) => {
            setConifg(response.data);
            cacheConfig(response.data);
          })
          .catch(() => {
            setConifg(cache?.data || null);
          });
      }
    };
    const getStoreOptions = () => {
      dispatch(getCurrencyOptions());
    };
    initialise();
    getStoreOptions();
  }, [dispatch]);

  return (
    config && (
      <ConfigContext.Provider
        value={{
          config,
        }}
      >
        {children}
      </ConfigContext.Provider>
    )
  );
}

export { ConfigProvider, ConfigContext };
