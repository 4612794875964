import axios from "axios";

// ----------------------------------------------------------------------
const TIMEOUT_IN_MS = 10000;

const axiosInstance = axios.create({
  timeout: TIMEOUT_IN_MS,
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    "x-uuid": process.env.REACT_APP_UUID,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default axiosInstance;
