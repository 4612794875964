import axios from "../utils/axios";

// STORE

// e.g. store theme, default currency
export const handleGetStoreConfig = () => axios.get(`/host/store`);

const handleGetStoreMetadata = async () => {
  const response = await axios.get(`/host/store/metadata`);
  return response.data;
};

const handleGetPaymentServices = async () => {
  const response = await axios.get(`/host/options/payments/services`);
  return {
    services: response.data,
  };
};

export const handleGetCompleteStoreInformation = async () => {
  const response = await Promise.all([
    handleGetStoreMetadata(),
    handleGetPaymentServices(),
  ]);
  return response.flat().reduce((result, obj) => ({ ...result, ...obj }), {});
};

export const handleGetStoreContacts = async () => {
  const { data } = await axios.get(`/host/store/contacts`);
  return data;
};

// NAVIGATIONS

export const handleGetNavigationById = async (id) => {
  const { data } = await axios.get(`/host/links/${id}`);
  return data;
};

export const getNavigationLinkByIds = async (ids) => {
  const { data } = await axios.get(`/host/links`, {
    params: {
      ...(ids && { ids: ids.toString() }),
    },
  });
  return data;
};

// CONTACT US FORM

export const sendContactForm = async (values) => {
  await axios.post(`/host/contact`, values);
};

// NEWSLETTER SUBSCRIPTION

export const handleCreateCustomerByEmail = (email) =>
  axios.post(`/host/customer`, {
    email,
  });

// PAGES

export const handleGetPageByName = (name) => axios.get(`/host/pages/${name}`);

// OPTIONS

export const handleGetStoreCurrencyOptions = () =>
  axios.get("/host/options/currencies");
