import { find, isEmpty, isNull } from "lodash";

export const cacheCallback = (cache, key, callback) => {
  const found = find(cache, { key });
  if (found) {
    return found.value;
  }
  const promise = callback(key);
  cache.push({ key, value: promise });
  return promise;
};

export const mapProductsToRequest = (products) => {
  return products.map((o) => {
    return {
      productUuid: o.id,
      ...(o.variantId && { variantUuid: o.variantId }),
      quantity: o.quantity,
    };
  });
};

export const createCheckoutSummaryRequest = ({
  currency,
  cart,
  discount,
  shippingId,
  shippingCountry,
}) => {
  return {
    currency,
    ...(cart && { products: mapProductsToRequest(cart) }),
    discount: isEmpty(discount) || isNull(discount) ? null : discount,
    ...(shippingId && {
      shipping: {
        key: shippingId,
        ...(shippingCountry && { country: shippingCountry }),
      },
    }),
  };
};
