import { paramCase } from "change-case";
import { defaultTo, filter, find, isEmpty } from "lodash";

// To avoid currencies remaining after the options are changed
export const findSelectedCurrency = (currency, options) => {
  if (!currency || isEmpty(options)) {
    return null;
  }
  const found = find(
    options,
    (o) => paramCase(o.code) === paramCase(defaultTo(currency.code, ""))
  );
  return found;
};

export const filterCartByCurrency = (currency, cart) => {
  if (!currency || isEmpty(cart)) {
    return [];
  }
  return filter(cart, (o) => {
    return (
      paramCase(defaultTo(o.price?.currency, "")) ===
      paramCase(defaultTo(currency.code, ""))
    );
  });
};

export const chooseCurrency = (
  prevSelectedCurrency,
  storeCurrency,
  capability,
  currencies
) => {
  const selectedCurrency = findSelectedCurrency(
    prevSelectedCurrency,
    currencies
  );
  return chooseCurrencyBySelectedCurrency(
    selectedCurrency,
    storeCurrency,
    capability
  );
};

export const chooseCurrencyBySelectedCurrency = (
  selectedCurrency,
  storeCurrency,
  capability
) => {
  if (!capability?.currency) {
    return storeCurrency;
  }
  return defaultTo(selectedCurrency, storeCurrency);
};
