import { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";

// ----------------------------------------------------------------------

export default function useStripe() {
  const getStripeClient = useCallback((publicKey) => {
    if (!publicKey) {
      return null;
    }
    return loadStripe(publicKey);
  }, []);

  return { getStripeClient };
}
