export const INITIAL_PAGE_VALUE = 0;
export const LAZY_PAGE_SIZE = 30;

const initial = {
  pagableData: {
    hasNext: false,
    page: 0,
    total: 0,
    data: [],
    filters: [],
  },
};

export default initial;
