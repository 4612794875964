import axios from "../utils/axios";

// PRODUCTS

export const handleGetProductByResource = async (resource, currency) => {
  const response = await axios.get(`/host/products/${resource}`, {
    params: {
      currency,
    },
  });
  return response.data;
};

export const handleGetProducts = async (
  page,
  size,
  currency,
  sortBy,
  sort,
  filterName,
  filters
) => {
  const response = await axios.get("/host/products", {
    params: {
      page,
      size,
      sortBy,
      sort,
      search: filterName,
      ...filters,
      currency,
    },
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });
  return response.data;
};

export const handleGetCollectionProductsByResource = async (
  resource,
  page,
  size,
  currency,
  sortBy,
  sort,
  filterName,
  filters
) => {
  const response = await axios.get(`/host/groups/${resource}/products`, {
    params: {
      page,
      size,
      sortBy,
      sort,
      search: filterName,
      ...filters,
      currency,
    },
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });
  return response.data;
};

// COLLECTIONS

export const handleGetCollectionByResource = async (resource) => {
  const response = await axios.get(`/host/groups/${resource}`);
  return response.data;
};

export const handleGetCollectionsByResource = async (
  resources,
  page,
  size,
  sortBy,
  sort,
  filterName
) => {
  const response = await axios.get(`/host/groups`, {
    params: {
      ...(resources && { resources: resources.toString() }),
      page,
      size,
      sortBy,
      sort,
      search: filterName,
    },
  });
  return response.data;
};

// FILTERS

export const handleGetProductsFilters = async (currency) => {
  const response = await axios.get("/host/products/filters", {
    params: {
      currency,
    },
  });
  return response.data;
};

export const handleGetCollectionFiltersByResource = async (
  resource,
  currency
) => {
  const response = await axios.get(
    `/host/groups/${resource}/products/filters`,
    {
      params: {
        currency,
      },
    }
  );
  return response.data;
};
