import { PATH_PAGE } from "src/routes/paths";
import axiosInstance from "src/utils/axios";
import { mapProductsToRequest } from "src/utils/requests";

const getStripeShipping = (form) => {
  return {
    name: `${form.firstName} ${form.lastName}`,
    address: {
      line1: form?.shippingAddress?.address,
      city: form?.shippingAddress?.city,
      country: form?.shippingAddress?.country,
      postal_code: form?.shippingAddress?.postcode,
    },
  };
};

// Will do the same as `getCheckoutSummary` but also use that response for the secret
export const getPaymentIntent = async (request) => {
  const response = await axiosInstance.post(
    "/host/checkout/stripe/session",
    request
  );
  return response.data;
};

// UTIL - All values are required
export const getPaymentIntentRequest = (form, cart, currency) => {
  return {
    ...(cart && { products: mapProductsToRequest(cart) }),
    currency,
    discount: form?.discountCode,
    shipping: {
      key: form?.shippingOption,
      country: form?.shippingAddress?.country,
    },
  };
};

export const startStripeJourney = async (form, context, onPaymentFailure) => {
  const { elements, client: stripe } = context.paymentsContext.stripe;
  const { id: orderId } = context;
  const stripeShipping = getStripeShipping(form);

  const { error } = await stripe.confirmPayment({
    elements,
    confirmParams: {
      // Make sure to change this to your payment completion page
      return_url: PATH_PAGE.checkoutRedirectUrl(orderId),
      shipping: stripeShipping,
    },
  });
  // This point will only be reached if there is an immediate error when
  // confirming the payment. Otherwise, your customer will be redirected to
  // your `return_url`. For some payment methods like iDEAL, your customer will
  // be redirected to an intermediate site first to authorize the payment, then
  // redirected to the `return_url`.
  if (error?.type === "card_error" || error?.type === "validation_error") {
    onPaymentFailure(orderId, null, error.message);
  } else {
    onPaymentFailure(orderId, null, error?.type);
  }
};
