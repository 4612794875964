import { add, isAfter } from "date-fns";
import { isEmpty } from "lodash";
import { createTransform } from "redux-persist";

const CART_KEY = "cart";
const CART_EXPIRY_IN_DAYS = 30;

const RECENTLY_VIEWED_PRODUCTS_KEY = "recentlyViewedProducts";
const RECENTLY_VIEWED_EXPIRY_IN_DAYS = 7;

const ProductExpireTransform = createTransform(
  (inboundState, key) => handleInboundState(inboundState, key),
  (outboundState, key) => handleOutboundState(outboundState, key)
);

const handleInboundState = (inboundState, key) => {
  if (canPerformAction(inboundState, key, RECENTLY_VIEWED_PRODUCTS_KEY)) {
    return inboundState.map((product) =>
      addProductExpiryDttm(product, RECENTLY_VIEWED_EXPIRY_IN_DAYS)
    );
  }
  if (canPerformAction(inboundState, key, CART_KEY)) {
    return inboundState.map((product) =>
      addProductExpiryDttm(product, CART_EXPIRY_IN_DAYS)
    );
  }
  return inboundState;
};

const handleOutboundState = (outboundState, key) => {
  if (canPerformAction(outboundState, key, RECENTLY_VIEWED_PRODUCTS_KEY)) {
    return outboundState.filter((product) => !isExpiredProduct(product));
  }
  if (canPerformAction(outboundState, key, CART_KEY)) {
    return outboundState.filter((product) => !isExpiredProduct(product));
  }
  return outboundState;
};

const canPerformAction = (outboundState, key, expected) =>
  key === expected && Array.isArray(outboundState) && !isEmpty(outboundState);

const isExpiredProduct = (product) => {
  try {
    return product?.expiryDttm
      ? isAfter(new Date(), new Date(product?.expiryDttm))
      : false;
  } catch (e) {
    console.error(e);
    return false;
  }
};

const addProductExpiryDttm = (product, expiryInDays) => {
  try {
    if (Object.prototype.hasOwnProperty.call(product, "expiryDttm")) {
      // Expiry already set, nothing left to do
      return product;
    }
    return {
      ...product,
      expiryDttm: add(new Date(), {
        days: expiryInDays,
      }),
    };
  } catch (e) {
    console.error(e);
    return product;
  }
};

export default ProductExpireTransform;
