import { createSlice } from "@reduxjs/toolkit";
import { handleGetStoreCurrencyOptions } from "src/service/host";

// ----------------------------------------------------------------------

const initialState = {
  currency: null, // Selected currency. Default is store currency
  options: {
    currencies: [],
  },
  loading: {
    currencies: false,
  },
  errors: {
    currencies: null,
  },
};

const slice = createSlice({
  name: "store",
  initialState,
  reducers: {
    // START LOADING
    startCurrencyOptionsLoading(state) {
      state.loading.currencies = true;
    },

    // HAS ERROR
    hasCurrencyOptionsError(state, action) {
      state.loading.currencies = false;
      state.errors.currencies = action.payload;
    },

    // GET CURRENCIES
    getCurrencyOptionsSuccess(state, action) {
      state.loading.currencies = false;
      state.options.currencies = action.payload;
    },
    updateCurency(state, action) {
      state.currency = action.payload;
    },
    resetCurrency(state) {
      state.currency = null;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { updateCurency, resetCurrency } = slice.actions;

// ----------------------------------------------------------------------

export function getCurrencyOptions() {
  return async (dispatch) => {
    dispatch(slice.actions.startCurrencyOptionsLoading());
    try {
      const response = await handleGetStoreCurrencyOptions();
      dispatch(slice.actions.getCurrencyOptionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasCurrencyOptionsError(error));
    }
  };
}
