import axios from "axios";
import { paramCase } from "change-case";
import { defaultTo, filter } from "lodash";
import { proxyServer } from "src/config";

// TODO - This payment journey needs QA!!!

const APPROVED = "APPROVED";
const DECLINED = "DECLINED";
const SUCCESS = "SUCCESS";

const createOrderPayload = (summary, form) => {
  return {
    merchantReference: summary.id,
    purchaseCountry: summary.currency.code,
    amount: { amount: summary.total.amount, currency: summary.currency.code },
    consumer: {
      givenNames: form.firstName,
      surname: form.lastName,
      email: form.email,
    },
    billing: {
      name: `${form.firstName} ${form.lastName}`,
      line1: form?.billingAddress?.address,
      area1: form?.billingAddress?.city,
      region: form?.billingAddress?.city,
      postcode: form?.billingAddress?.postcode,
      countryCode: form?.billingAddress?.country,
    },
    shipping: {
      name: `${form.firstName} ${form.lastName}`,
      line1: form?.shippingAddress?.address,
      area1: form?.shippingAddress?.city,
      region: form?.shippingAddress?.city,
      postcode: form?.shippingAddress?.postcode,
      countryCode: form?.shippingAddress?.country,
    },
    items: filter(
      summary.items,
      (o) => paramCase(defaultTo(o.type, "")) === "product"
    ).map((item) => {
      return {
        name: item.name,
        sku: item.sku,
        quantity: item.quantity,
        price: { amount: item.price.amount, currency: summary.currency.code },
      };
    }),
    discounts: [
      {
        displayName: summary.invoice.discount.name,
        amount: {
          amount: summary.invoice.discount.amount,
          currency: summary.currency.code,
        },
      },
    ],
    shippingAmount: {
      amount: summary.shippingOption.cost,
      currency: summary.currency.code,
    },
  };
};

const axiosInstance = axios.create({
  baseURL: `${proxyServer}/api/ext/clearpay`,
  timeout: 10000,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers["Content-Type"] = "application/json";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export const startClearpayJourney = async (
  form, // Checkout values from form
  context, // BE context
  onPaymentSuccess,
  onPaymentFailure,
  onPaymentCancel
) => {
  const response = await createOrder(context, form);
  const createOrderToken = response.token;

  window.AfterPay.initialize({ countryCode: context.country.code });
  // To avoid triggering browser anti-popup rules, the AfterPay.open()
  // function must be directly called inside the click event listener
  window.AfterPay.open();
  // If you don't already have a checkout token at this point, you can
  // AJAX to your backend to retrieve one here. The spinning animation
  // will continue until `AfterPay.transfer` is called.
  // If you fail to get a token you can call AfterPay.close()
  window.AfterPay.onComplete = (event) => {
    const { status, orderToken } = event.data;
    if (status === SUCCESS) {
      // The consumer confirmed the payment schedule.
      // The token is now ready to be captured from your server backend.
      capturePayment(context, orderToken, onPaymentSuccess, onPaymentFailure);
    } else {
      // The consumer cancelled the payment or closed the popup window.
      onPaymentCancel(context.id);
    }
  };
  window.AfterPay.transfer({ token: createOrderToken });
};

const createOrder = async (context, form) => {
  const auth = context.paymentOption.auth;
  const payload = createOrderPayload(context, form);
  const response = await axiosInstance.post(`v2/checkouts`, payload, {
    auth: {
      username: auth.username,
      password: auth.password,
    },
  });
  return response.data;
};

const capturePayment = async (
  context,
  orderToken,
  onPaymentSuccess,
  onPaymentFailure
) => {
  const auth = context.paymentOption.auth;
  const orderId = context.id;
  const payload = {
    token: orderToken,
    merchantReference: orderId,
  };
  const response = await axiosInstance.post(`v2/payments/capture`, payload, {
    auth: {
      username: auth.username,
      password: auth.password,
    },
  });
  const data = response.data;
  switch (data.status) {
    case APPROVED:
      onPaymentSuccess(orderId, data.id, {
        amountInMinorUnits: context?.total?.amountInMinorUnits,
        currency: context?.currency?.code,
        country: context?.country?.code,
      });
      break;
    case DECLINED:
      onPaymentFailure(orderId);
      break;
    default:
      onPaymentFailure(orderId);
  }
};
